import React, {useEffect, useState} from 'react';
import moment from 'moment';
export default function DateTime({datas}) {
    const [datetime, setDatetime] = useState({
        date: "-",
    })
    useEffect(() => {
        if(datas){
            setDatetime({
                date: moment(datas).format('DD.MM.YYYY HH:mm'),
            })
        }
    }, [datas])
    return(
        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
            <span className="">
                {datetime.date}
            </span>
        </td>
    )
}
