import MudurluklerDurumPie from "../widget/Gostergeler/MudurluklerDurumPie";
import MudurluklerDurumLine from "../widget/Gostergeler/MudurluklerDurumLine";
import FirmaDurumLine from "../widget/Gostergeler/FirmaDurumLine";
import PersonelCount from "../widget/Gostergeler/PersonelCount";
import AltBirimCount from "../widget/Gostergeler/AltBirimCount";
import EnCokCalisan from "../widget/Gostergeler/EnCokCalisan";
import EnAzCalisan from "../widget/Gostergeler/EnAzCalisan";
import PersonelMesaiDurumu from "../widget/Gostergeler/PersonelMesaiDurumu";
import CihazlarinSonAktiflikZamanlari from "../widget/Gostergeler/CihazlarinSonAktiflikZamanlari";

import '../assets/Gostergeler.css'
export default function Gostergeler() {
    const auths = JSON.parse(localStorage.getItem('userData')).dashboards;
    return(
        <>

                <div className="flex flex-col p-1 lg:p-10 mb-20">
                    <div className="flex flex-col  shadow-lg shadow-gray-300 p-2 md:p-4 rounded">
                        <div className="pb-3 flex flex-row flex-wrap justify-center sm:justify-between items-center mb-3 border-b-2 border-gray-200">
                            <span className="text-2xl font-bold mr-3">Müdürlükler</span>
                            { auths.RecordCount?.departments &&
                                <AltBirimCount/>
                            }

                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-5  w-full gap-3">
                            { auths.GraphicPie?.Mesai && <div className="col-span-1 lg:col-span-1 xl:col-span-2 p-3  shadow-lg shadow-gray-300 rounded-xl border border-gray-200">
                                { auths.GraphicPie?.Mesai ?
                                    <MudurluklerDurumPie/>
                                    :
                                    <div className="flex flex-row justify-center my-6 text-2xl">
                                        Gösterge bulunamadı
                                    </div>
                                }
                            </div>}
                            { auths.GraphicXY?.Mesai &&<div className="col-span-1 lg:col-span-1 xl:col-span-3 p-3  shadow-lg shadow-gray-300 rounded-xl border border-gray-200">
                                { auths.GraphicXY?.Mesai ?
                                <MudurluklerDurumLine/>
                                    :
                                    <div className="flex flex-row justify-center my-6 text-2xl">
                                        Gösterge bulunamadı
                                    </div>
                                }

                            </div>}
                        </div>
                    </div>
                    <div className="flex flex-col  shadow-lg shadow-gray-300 p-3 rounded mt-10">
                        <div className="pb-3 flex flex-row flex-wrap justify-center sm:justify-between items-center mb-3 border-b-2 border-gray-200">
                            <span className="text-2xl font-bold mr-3">Firma</span>

                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 w-full gap-3">

                            { auths.Custom?.Mesai && <div className="col-span-1 lg:col-span-1 xl:col-span-2 p-3  shadow-lg shadow-gray-300 rounded-xl border border-gray-200">
                                { auths.Custom?.Mesai ?
                                    <PersonelMesaiDurumu/>
                                    :
                                    <div className="flex flex-row justify-center my-6 text-2xl">
                                        Gösterge bulunamadı
                                    </div>
                                }



                            </div>}
                            { auths.GraphicXY?.Mesai && <div className="col-span-1 lg:col-span-1 xl:col-span-3 p-3  shadow-lg shadow-gray-300 rounded-xl border border-gray-200">

                                { auths.GraphicXY?.Mesai ?
                                    <FirmaDurumLine/>
                                    :
                                    <div className="flex flex-row justify-center my-6 text-2xl">
                                        Gösterge bulunamadı
                                    </div>
                                }
                            </div>}
                        </div>
                    </div>

                    { auths.ComboBoxList?.Mesai &&<div className="flex flex-col  shadow-lg shadow-gray-300 p-3 rounded mt-10">
                        <div className="pb-3 flex flex-row flex-wrap justify-center sm:justify-between items-center mb-3 border-b-2 border-gray-200">
                            <span className="text-2xl font-bold mr-3">Personel Durum</span>
                            { auths.RecordCount?.users &&
                                <PersonelCount/>
                            }
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-3">
                            <div className="col-span-1  p-3  shadow-lg shadow-gray-300 rounded-xl border border-gray-200">
                                <EnCokCalisan/>
                            </div>
                            <div className="col-span-1  p-3  shadow-lg shadow-gray-300 rounded-xl border border-gray-200">
                                    <EnAzCalisan/>
                            </div>
                        </div>

                    </div>}
                    { auths.ComboBoxList?.Cihaz && <div className="flex flex-col  shadow-lg shadow-gray-300 p-3 rounded mt-10">
                        <div className="pb-3 flex flex-row flex-wrap justify-center sm:justify-between items-center mb-3 border-b-2 border-gray-200">
                            <span className="text-2xl font-bold mr-3">Cihazların Son Aktiflik Zamanları</span>
                        </div>
                        { auths.ComboBoxList?.Cihaz ?
                            <CihazlarinSonAktiflikZamanlari/>
                            :
                            <div className="flex flex-row justify-center my-6 text-2xl">
                                Gösterge bulunamadı
                            </div>
                        }
                    </div>}
                </div>
        </>
    )
}
