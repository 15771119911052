import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import request from "../../request";
import { toast } from "react-toastify";
import { set } from 'ol/transform';
export default function MudurluklerDurumPie() {
    const [options, setOptions] = useState({
        labels: [],
        legend: {
            position: 'bottom'
        }
    })
    const [series, setSeries] = useState([])
    const [loading, setLoading] = useState(true);
    const getSeries = () => {
        setLoading(true)
        request.getDashboardData("GraphicPie:Mesai:MudurluklerDurum").then((res) => {
            setOptions({ ...options, labels: Object.values(res.data.data.data.names) })
            const series_ = Object.values(res.data.data.data.columns).map((item) => {
                return item[1]
            })
            setSeries(series_)
        }).catch((err) => {
            toast.error('Hata Oluştu' + err)
        }).finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        getSeries()
    }, [])
    return (
        <div className="flex flex-col">
            <span className="text-xl mb-3 pb-2 border-b w-full">Müdürlükler geç kalma karşılaştırma</span>
            <div className="row">
                {loading ?
                    <div className="flex justify-center items-center h-96">
                        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
                    </div>
                    :
                    <div className="mixed-chart">
                        <Chart
                            options={options}
                            series={series}
                            type="pie"
                            width="100%"
                        />
                    </div>
                }
            </div>
        </div>
    )

}
