import React, {useState} from 'react'
import '../assets/Login.css'
import request from "../request";
import {toast, ToastContainer} from "react-toastify";
export default function Login() {
    const [inputType, setInputType] = useState("password")
    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
    })
    const changeHandler = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value,
        })
    }
    const login = () => {

        request.login(loginData)
    }
    const toggleInput = () => {
        setInputType(inputType === "password" ? "text" : "password")
    }
    return (
        <>
            <div className="login-page flex flex-row justify-between w-screen">
                <div className="hidden lg:flex w-1/2  flex-col justify-center items-center">
                    <img src="/login.png" className="max-h-[500px]" alt="Login"/>
                </div>
                <div className="w-full lg:w-1/2 flex flex-col justify-center items-center">
                    <div className="bg-white p-10 rounded-3xl w-[400px] max-w-[96vw] m-4">
                        <div className="flex flex-col justify-center ">
                            <div className="text-center">
                                <h1 className="mb-10 text-4xl">LOGO</h1>
                            </div>
                            <h1 className="mb-10 text-3xl">Hoş geldiniz</h1>

                            <label htmlFor="">TCKN / Eposta</label>
                            <input type="text" name="email" onChange={changeHandler} className="w-full p-2 border border-gray-300 rounded-lg my-2"/>
                            <label htmlFor="">Şifre</label>
                            <div class="relative">
                                <input type={inputType} name="password" onKeyPress={(e)=>{e.key==='Enter' && login()}} onChange={changeHandler} className="w-full p-2 border border-gray-300 rounded-lg my-2"/>
                                <button class="absolute top-4 right-4" onClick={toggleInput}>{inputType === "password" ? "Göster" : "Gizle"}</button>
                            </div>
                            <button className="bg-slate-700 mt-4 hover:bg-slate-600 text-white px-4 py-2 rounded-lg" onClick={()=>login()}>Giriş Yap</button>

                        </div>
                    </div>
                </div>

            </div>
            <ToastContainer  position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover theme="light"/>
        </>
    )
}
