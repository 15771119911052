import Select from '../inputs/Select';
import {useEffect,useState} from "react";
import request from "../../request";
import moment from "moment";
import {Autocomplete} from "@mui/material";
import TextField from "@mui/material/TextField";
export default function PersonelMesaiDurumu() {
    const [datas, setDatas] = useState({
        "gelmedi":0,
        "az_calisti":0,
        "normal":0,
        "mazeretli":0,
        "fazla":0
    });
    useEffect(() => {
        getData()
    }, [])
    const getData = (v = 1) => {
        const params = {
            limit: 100,
            page: 1,
            sorts:{},
            filters:{
                "mesai_baslama_zamani":{"type":4,"guiType":"datetime","filter": moment().format("YYYY-MM-DD")+" 00:00:00","filter2":moment().format("YYYY-MM-DD")+" 23:59:59"}
            }
        }
        request.list('personel_mesaileri',params).then(res => {
            let _data={
                "gelmedi":0,
                "az_calisti":0,
                "normal":0,
                "mazeretli":0,
                "fazla":0
            }
            res.data.data.records.forEach((item)=>{
                if(v === 1){
                    if(item.giris_durumu === "Gelmedi"){
                        _data.gelmedi++
                    }else if(item.giris_durumu.search('fazla') !== -1){
                        _data.fazla++
                    }else if(item.giris_durumu.search('az çalıştı')  !== -1){
                        _data.az_calisti++
                    }else if(item.giris_durumu.search('İzni var')  !== -1){
                        _data.mazeretli++
                    }else {
                        _data.normal++
                    }
                }else{
                    if(item.cikis_durumu === "Gelmedi"){
                        _data.gelmedi++
                    }else if(item.cikis_durumu.search('fazla') !== -1){
                        _data.fazla++
                    }else if(item.cikis_durumu.search('az çalıştı')  !== -1){
                        _data.az_calisti++
                    }else if(item.cikis_durumu.search('İzni var')  !== -1){
                        _data.mazeretli++
                    }else {
                        _data.normal++
                    }
                }
            })
            setDatas(_data)
        })
    }
    const mudurlukChange = (e) => {
        console.log(e.target.value)
    }
    const setValue = (v) => {
        getData(v.value)
    }
    return(
            <>
                <div className="pb-3 flex flex-row flex-wrap justify-center sm:justify-between items-center mb-3 border-b border-gray-200">
                    Personel Mesai Durumu
                    <Autocomplete
                        className={"w-1/3  "}
                          options={[{label: "Giriş", value: 1}, {label: "Çıkış", value: 2}]}
                            defaultValue={{label: "Giriş", value: 1}}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          onChange={(e,v) => {setValue(v)}}
                          renderInput={(params) => <TextField {...params}  classes={{root:"text-2xl"}} />}
                    />
                </div>
                <Select tableName={'personel_mesaileri'} name={'mudurluk_adi'} label={'Müdürlük Adı'}  onChange={mudurlukChange}/>
                <div className="flex flex-col mt-2">
                    <div className="flex flex-row border-b justify-between items-center p-1">
                        <span className="bg-red-500 text-white px-3 rounded p-1">{datas.gelmedi}</span>
                        <span>Gelmeyen</span>
                    </div>
                    <div className="flex flex-row border-b justify-between items-center p-1">
                        <span className="bg-orange-500 text-white px-3 rounded p-1">{datas.az_calisti}</span>
                        <span>Az Çalışan</span>
                    </div>
                    <div className="flex flex-row border-b justify-between items-center p-1">
                        <span className="bg-slate-500 text-white px-3 rounded p-1">{datas.normal}</span>
                        <span>Normal</span>
                    </div>
                    <div className="flex flex-row border-b justify-between items-center p-1">
                        <span className="bg-sky-500 text-white px-3 rounded p-1">{datas.mazeretli}</span>
                        <span>Mazeretli</span>
                    </div>
                    <div className="flex flex-row justify-between items-center p-1">
                        <span className="bg-green-500 text-white px-3 rounded p-1">{datas.fazla}</span>
                        <span>Çok Çalışan</span>
                    </div>
                </div>

            </>
    )
}
