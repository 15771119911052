import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import request from "../../request";

export default function Select({defaultValue,label,tableName,name,onChange,type}){
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([])
    const [value, setValue] = useState("")


    useEffect(() => {
        if (value?.value){
            onChange({
                target: {
                    name: name,
                    value: value.value.toString()
                }
            })
        }else{
            onChange({
                target: {
                    name: name,
                    value: ""
                }
            })
        }
    }, [value])
    useEffect(() => {
        if(type === "filters" && defaultValue){
            request.columnData(tableName,name).then((res) => {
                let options = {}
                res.data.results.forEach((item) => {
                    options[item.id] = item.text
                })
                setValue({label: options[defaultValue], value: defaultValue})

            })
        }
        else if(typeof  defaultValue === typeof [] && defaultValue.length>0){
            setValue({label: defaultValue[0].display, value: defaultValue[0].source})
        }
    }, [defaultValue,options])
    useEffect(() => {
        request.columnData(tableName,name).then((res) => {
            let backData = res.data.results;
            let newData = [];
            backData.forEach((item) => {
                newData.push({label: item.text, value: item.id});
            })
            setOptions(newData);

        }).finally(() => {
            setLoading(false);
        })
    }, [name])
    return(
        <div className=" flex flex-row">

            {
                loading ?
                    <div className="w-full p-3">
                        Yükleniyor...
                    </div>
                    :
                    <Autocomplete
                        className="w-full"
                        disablePortal
                        options={options}
                        value={value}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(e,v) => {setValue(v)}}
                        renderInput={(params) => <TextField {...params} label={label} classes={{root:"text-2xl"}} />}
                    />
            }


        </div>
    )
}
