import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {Button} from "@mui/material";
import Konum from "../Mesai/Konum";
import QRCode from "../Mesai/QRCode";
import Tarayici from "../Mesai/Tarayici";
import Tag from "../Mesai/Tag";
import {useState} from "react";

export default function Mesai() {
    const [konumVisible,setKonumVisible] = useState(false)
    const [qrVisible,setqrVisible] = useState(false)
    const [tarayiciVisible,setTarayiciVisible] = useState(false)
    const [tagVisible,setTagVisible] = useState(false)
    return(
        <div className="px-4">
            <Accordion className="!bg-slate-700 ">
                <AccordionSummary className=" !bg-slate-500 !rounded-xl">
                    <span  className="text-white">Mesai Başlat</span>
                </AccordionSummary>
                <AccordionDetails className="bg-slate-700 text-white">
                <div className="flex flex-col">
                    <Button variant="outlined" onClick={()=>setKonumVisible(true)} className="!text-white !border-white hover:!bg-white/20">
                        Konum ile mesai başlat
                    </Button>

                    <div className="my-1"></div>
                    <Button variant="outlined" onClick={()=>setTarayiciVisible(true)}  className="!text-white !border-white  hover:!bg-white/20">
                        Tarayıcı ile mesai başlat
                    </Button>
                    <div className="my-1"></div>
                    <Button variant="outlined"   onClick={()=>setqrVisible(true)} className="!text-white !border-white  hover:!bg-white/20">
                        QR Kod ile mesai başlat
                    </Button>
                    <div className="my-1"></div>
                    <Button variant="outlined"   onClick={()=>setTagVisible(true)} className="!text-white !border-white  hover:!bg-white/20">
                        Tag ile mesai başlat
                    </Button>

                </div>
                </AccordionDetails>
            </Accordion>
            <Konum visible={konumVisible} setVisible={setKonumVisible}/>
            {qrVisible &&
                <QRCode visible={qrVisible} setVisible={setqrVisible}/>
            }
            <Tarayici visible={tarayiciVisible} setVisible={setTarayiciVisible}/>
            <Tag visible={tagVisible} setVisible={setTagVisible}/>


        </div>

    )
}
