import { HiOutlineUserGroup} from "react-icons/hi";
import { BsPerson} from "react-icons/bs";
import React from "react";
import UserUpdate from "../../../components/UserUpdate";

const userId = localStorage.getItem("userData")?.user?.id || 0
export default {
    menu:[

        {
            title:"İnsan Kaynakları",
            icon:<HiOutlineUserGroup  className="text-2xl text-white"/>,
            show:false,
            sub:[
                {
                    component: <UserUpdate></UserUpdate>
                }
            ]
        },


    ]
}
