import Webcam from "react-webcam";
import React,{useEffect,useState} from "react";
import {toast} from "react-toastify";
import {MdCameraswitch} from "react-icons/md";
export default function Kamera({ setData}) {
    const [buttonLoading,setButtonLoading] = useState(false)
    const [cameraMode,setCameraMode] = useState("user")
    const [loading,setLoading] = useState(false)

    const videoConstraints = {
        width: 1280,
        height: 720,
        maxHeight:"70vh",
        facingMode: cameraMode
    };
    useEffect(()=>{
        setLoading(true)
        setTimeout(()=>{
            setLoading(false)
        },100)
        },[cameraMode])
    return(

        <div className="w-100 ">
            {!loading&&
                <Webcam
                    className={"max-h-[60vh]"}
                    audio={false}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    mirrored={true}
                    onUserMediaError={()=>{
                        toast.warning("Bir hata oluştu. Lütfen kameranızı kontrol edin.");
                    }}
                >
                    {({ getScreenshot }) => (
                        <div className="flex flex-row justify-between p-1">
                            <button className="bg-sky-500 text-white  py-2 px-4 rounded mt-2 " onClick={()=> setCameraMode(cameraMode === 'user' ?{ exact: "environment" }:"user")}><MdCameraswitch/></button>

                            <button disabled={buttonLoading} className={` ${buttonLoading?'bg-gray-400':'bg-teal-700 hover:bg-teal-600'}  text-white font-bold py-2 px-4 rounded mt-2`}
                                    onClick={() => {
                                        setButtonLoading(true)
                                        const imageSrc = getScreenshot()
                                        setData(imageSrc)
                                    }}
                            >
                                Fotoğraf Çek
                            </button>


                        </div>
                    )}
                </Webcam>
            }
        </div>
    )
}
