import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import request from "../../request";
export default function MudurluklerDurumLine() {
    const [options, setOptions] = useState({
        labels: [],
        stroke: {
            curve: 'smooth',
        }

    })
    const [series, setSeries] = useState([])
    const getSeries = () => {
        request.getDashboardData("GraphicXY:Mesai:MudurluklerDurum").then((res) => {

            let series_ = []
            for (const val of Object.values(res.data.data.data.columns)) {
                series_.push({
                    name: res.data.data.data.names[val[0]],
                    data: val.slice(1).map((item) => {
                        return item === null ? 0 : item
                    })
                })
            }
            setSeries(series_)
            setOptions({...options, labels: res.data.data.axis.x.categories})
        })
    }
    useEffect(() => {
        getSeries()
    }, [])
    return (
        <div className="flex flex-col">

            <span className="text-xl mb-3 pb-2 border-b w-full">Müdürlükler geç kalma (gün/dk)</span>
            <div className="row">
                <div className="mixed-chart">
                    <Chart
                        options={options}
                        series={series}
                        type="line"
                        width="100%"
                    />
                </div>
            </div>
        </div>
    )

}
