import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {BiSelectMultiple} from "react-icons/bi";
import {useEffect, useState} from "react";
import request from "../../request";

export default function Select({className,defaultValue,label,tableName,name,onChange,type}){
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([])
    const [value, setValue] = useState(null)
    const [inputValue, setInputValue] = useState("")
    const tumunuSec = () => {
        let newData = options.filter((item) => {
            if(!value.find((i) => i.value === item.value)) return item.label.toLowerCase().includes(inputValue.toLowerCase());
        })

        newData = newData.concat(value);
        setValue(newData);
    }
    useEffect(() => {


        if(type === "filters" && defaultValue){
            setValue(defaultValue.map((item) => {
                return {label: item, value: item}
            }))
            request.columnData(tableName,name).then((res) => {

                let options = res.data.results
                let newData = defaultValue.map((item) => {
                    let _option =  options.find((i) => i.id === item)
                    return _option && {label: _option.text, value: _option.id}
                })
                console.log("options new ,",newData)
                setValue(newData);


            })


        }else{
            if(typeof  defaultValue === typeof [] && defaultValue?.length>0){

                let newData = defaultValue.map((item) => {
                    return {label: item.display, value: item.source}
                })
                setValue(newData);
            }
        }
    },[])
    useEffect(() => {
        if(typeof value===typeof [] && value !== null) {
            if(value.length<=0){
                onChange({
                    target: {
                        name: name,
                        value:  null
                    }
                })
            }else if(type === "filters"){
                onChange({
                    target: {
                        name: name,
                        value:  value.map((item) => item.value)
                    }
                })
            }
            else{
                let _values =value.map((item) => `${item.value}`)

                onChange({
                    target: {
                        name: name,
                        value:  JSON.stringify(_values)
                    }
                })
            }
        }

    }, [value,name])



    useEffect(() => {
        getOptions().then((res) => {
            setOptions([])
            let backData = res.data.results;
            let newData = [];
            backData.forEach((item) => {
                newData.push({label: item.text, value: item.id});
            })
            setOptions(newData);

        }).finally(() => {
            setLoading(false);
        })
    }, [name])
    const getOptions = async () => {
        return  await  request.columnData(tableName,name)
    }
    return(
        <div className={` flex flex-row ${className}`} >

            {
                loading ?
                    <div className="w-full p-3 border rounded border-gray-400">
                        Yükleniyor...
                    </div>
                    :
                    <Autocomplete
                        className="w-full"
                        multiple
                        disablePortal
                        options={options}
                        value={value === null ? [] : value}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onInputChange={(event, newInputValue) => {
                            event?.type === "change" && setInputValue(newInputValue);
                        }}
                        onChange={(e,v) => {setValue(v)}}
                        renderInput={(params) => <TextField {...params} label={label} classes={{root:"text-2xl"}} />}
                    />
            }

            {

                <button className="w-14 bg-slate-500 rounded ml-2 flex justify-center items-center flex-row" onClick={tumunuSec}>
                    <BiSelectMultiple className="text-2xl text-white"/>
                </button>
            }

        </div>
    )
}
