import {HiOutlineDocumentSearch, HiOutlineUserGroup} from "react-icons/hi";

import GecKalmaRaporu from "../GecKalmaRaporu";
import {BsBuildings, BsCalendar2Plus, BsCalendarDate, BsPeople, BsPerson} from "react-icons/bs";
import {AiOutlineFieldTime} from "react-icons/ai";
import {RiRestTimeLine} from "react-icons/ri";
import {RxCountdownTimer} from "react-icons/rx";
import { MdOutlineMoreTime, MdOutlineRule} from "react-icons/md";
import {TbReplaceFilled} from "react-icons/tb";
const userId = localStorage.getItem("userData")?.user?.id || 0

export default {
    menu:[
        {
            title:"Rapor",
            icon:<HiOutlineDocumentSearch className="text-2xl text-white"/>,
            show:false,
            sub:[
                {
                    component: <GecKalmaRaporu></GecKalmaRaporu>
                }
            ]
        },
        {
            title:"İnsan Kaynakları",
            icon:<HiOutlineUserGroup  className="text-2xl text-white"/>,
            show:false,
            sub:[
                {
                    title:"Alt Birimler",
                    icon:<BsBuildings className="text-2xl text-white"/>,
                    link:"/tablolar/departments"
                },
                {
                    title:"Personel Grupları",
                    icon:<BsPeople className="text-2xl text-white"/>,
                    link:"/tablolar/personel_gruplar"
                },
                {
                    title:"Personeller",
                    icon:<BsPerson className="text-2xl text-white"/>,
                    link:"/tablolar/users"
                }
            ]
        },
        {
            title:"İzin İşlemleri",
            icon:<AiOutlineFieldTime className="text-2xl text-white"/>,
            show:false,
            sub:[
                {
                    title:"İzinler",
                    icon:<RiRestTimeLine className="text-2xl text-white"/>,
                    link:"/tablolar/personel_izinler"
                },
                {
                    title:"Saatlik İzinler",
                    icon:<RxCountdownTimer className="text-2xl text-white"/>,
                    link:"/tablolar/personel_saatlik_izinler"
                },
                {
                    title:"Toplu İzin Ekleme",
                    icon: <MdOutlineMoreTime className="text-2xl text-white"/>,
                    link:"/tablolar/saatlik_izin_ekleme_gorevleri"
                }
            ]
        },
        {
            title:"Mesai İşlemleri",
            icon:<BsCalendarDate  className="text-2xl text-white"/>,
            show:false,
            sub:[

                {
                    title:"Mesai Kuralları",
                    icon:<MdOutlineRule className="text-2xl text-white"/>,
                    link:"/tablolar/mesai_kurallari"
                },

                {
                    title:"Mesai Geçişleri",
                    icon:<TbReplaceFilled className="text-2xl text-white"/>,
                    link:"/tablolar/personel_mesai_gecisleri"
                },
                {
                    title:"Personel Mesaileri",
                    icon:<BsCalendar2Plus className="text-2xl text-white"/>,
                    link:"/tablolar/personel_mesaileri"
                }
            ]

        },
    ]
}
