import Dual from "./Dual";
import React from "react";
import {BsPerson} from "react-icons/bs";


export default function UserUpdate() {
    const [open, setOpen] = React.useState(false);
    return(
        <div>
            <label onClick={()=>setOpen(true)} className=" bg-slate-700 flex items-center p-2 text-base font-normal text-gray-900 rounded-lg   hover:bg-slate-500/70">
                <div className="flex"><BsPerson className="text-2xl text-white"/></div>
                <span className="ml-3 text-white">Profil Güncelle</span>
            </label>
            <Dual tableName={"users"} id={JSON.parse(localStorage.getItem("userData"))?.user?.id} type={"edit"} visible={open} setVisible={setOpen}></Dual>
        </div>
    )
}
