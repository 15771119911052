import React, {useEffect, useState} from 'react'
import {AiFillSetting ,AiOutlineClose,AiOutlineLogout} from 'react-icons/ai'
import Settings from "./Settings";
import profileDefault from '../../assets/profile-default.png'

export default function Profile({closeAside}) {
    const user_data=localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')):undefined
    const [user,setUser]=useState(user_data.user)
    const [profilePicture,setProfilePicture]=useState({})
    const [settingsVisible,setSettingsVisible] = useState(false)
    const logOut=()=>{
        localStorage.clear()
        window.location.reload()
    }
    useEffect(() => {
        setUser(user_data.user)
        const photo = JSON.parse(user.profile_picture)[0]
        photo ? setProfilePicture('https://bulut.kuvarssoft.com/uploads/'+ photo.destination_path + photo.file_name):
            setProfilePicture(profileDefault)
    }, [])
    return(
        <>
            <div className="flex justify-between mt-3 mx-5">
                <AiOutlineLogout className=" text-3xl cursor-pointer text-white" onClick={logOut}/>
                <div className="flex flex-row">
                    {user_data.menu.additionalLinks.find(x=>x.id == 7) && 
                    <AiFillSetting className="text-3xl cursor-pointer text-white "  onClick={()=>setSettingsVisible(true)}/>
                    }
                    <AiOutlineClose onClick={closeAside} className="text-3xl cursor-pointer md:hidden text-white ml-2"/>
                </div>
            </div>
            <div className="flex flex-col items-center my-4">
                <img src= {profilePicture} alt="profile" className=" rounded-full w-24 h-24" />
                <div className="flex flex-col items-center">
                    <span className="text-white text-2xl font-semibold">{user.name_basic} {user.surname}</span>
                    <span className="text-gray-400  font-semibold">
                       RFID: {user.rfid_kart_id}
                    </span>
                    <span className="text-gray-400  font-semibold">
                        {user.email}
                    </span>
                    {user.mesaiden_muaf?
                        <span className="text-gray-400  font-semibold"> Mesaiden Muaf</span>:
                        <span className="text-gray-400  font-semibold"> Mesaiden Muaf Değil</span>
                    }
                    <span className="text-gray-400  font-semibold">
                        Sicil No: {user.sicil_no ?? 'Sicil No Bulunamadı'}
                    </span>
                </div>
            </div>
            <Settings visible={settingsVisible} setVisible={setSettingsVisible} />
        </>
    )
}
