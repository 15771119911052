import Dialog from "@mui/material/Dialog";
import {AiOutlineCloseCircle} from "react-icons/ai";
import request from "../../request";
import {toast} from "react-toastify";
import {useState} from "react";
export default function Tarayici({visible,setVisible}) {
    const [buttonLoading,setButtonLoading] = useState(false)
    const mesaiBaslat = () => {
        setButtonLoading(true);
        request.tarayiciIleMesaiBaslat().then((res)=>{
            if(res.data.status === 'success'){

                toast.success("Tarayıcı ile mesai başlatıldı.")
                setVisible(false)
            }else{
                toast.error(res.data.data.message)
            }
        }).finally(()=>{
            setButtonLoading(false)
        })
    }
    return(
        <div>
            <Dialog onClose={()=>setVisible(false)} open={visible}>
                <div className="w-[420px] flex flex-col p-3 max-w-full">
                    <div className="p-1 flex flex-row justify-between">
                        <h1>Tarayıcı ile mesai başlat</h1>
                        <AiOutlineCloseCircle className="text-2xl text-red-600 cursor-pointer" onClick={()=>setVisible(false)}/>
                    </div>
                    <hr className="my-1"/>
                    <div className="mt-4">
                        Tarayıcı ile mesai başlatmak için onaylayınız.
                        <div className="flex flex-row mt-5 justify-end">
                            <button disabled={buttonLoading} className={` ${buttonLoading?'bg-gray-400':'bg-teal-700 hover:bg-teal-600'}  text-white font-bold py-2 px-4 rounded mt-2`} onClick={()=>mesaiBaslat()}>Onayla</button>
                            <button className="bg-red-500 text-white  py-2 px-4 rounded mt-2 ml-2">İptal</button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>

    )
}
