import Dialog from "@mui/material/Dialog";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Switch from "@mui/material/Switch";
import { useState, useEffect } from "react";
import request from "../../request";
import { toast } from "react-toastify";
export default function Settings({ visible, setVisible }) {
    const [value, setValue] = useState(0);
    const [id, setId] = useState(0)
    const [browserIpControlByPass, setBrowserIpControlByPass] = useState(true)
    const [disable_take_photo, setdisable_take_photo] = useState(false)
    const [cihaz_izleme, setcihaz_izleme] = useState(false)
    const [settings, setSettings] = useState({
        "smtp_server": "",
        "smtp_port": "",
        "browserMesaiKontrolIp": [],
        "gec_kalma_zaman_asimi": 0,
        "browserIpControlByPass": false,
        "firma_logo_url": "",
        "site_baslik": "",
        "smtpUser": "",
        "smtpPassword": "",
        "disable_take_photo": false,
        "cihaz_izleme": false,
        "cihaz_izleme_e_postalar": ""
    })

    const changeHandler = (e, v) => {
        let data = settings;
        data[e.target.name] = v ?? e.target.value;
        setSettings(data);
    }
    const saveData = () => {
        const params = {
            id: id,
            in_form_column_name: "value",
            single_column: "value",
            value: JSON.stringify(settings)
        }
        request.update('settings', id, params).then(res => {
            toast.success("Kaydedildi")
            setVisible(false)
        })
    }
    useEffect(() => {
        const params = {
            limit: 1,
            page: 1,
            sorts: {},
            filters: {}
        }
        if (visible) {
            request.list('settings', params).then(res => {

                let data = JSON.parse(res.data.data.records[0].value)
                if (data != null) {
                    console.log(data)
                    setId(res.data.data.records[0].id)
                    setSettings(data)
                    setBrowserIpControlByPass(data.browserIpControlByPass)
                    setdisable_take_photo(data.disable_take_photo)
                    setcihaz_izleme(data.cihaz_izleme)
                    data.site_baslik != null ? document.title = data.site_baslik : document.title = "PDKS"
                } else {
                    document.title = "PDKS"
                }
            })
        }
    }, [visible])
    return (
        <Dialog open={visible} onClose={() => setVisible(false)} classes={{
            paper: "w-[94vw] xs:w-[70vw] sm:w-[65vw] md:w-[500px]"
        }}>
            <div className="p-6">
                <Tabs value={value} onChange={(e, v) => setValue(v)} variant="scrollable"
                    scrollButtons="auto">
                    <Tab label="SMTP" />
                    <Tab label="MESAI" />
                    <Tab label="CIHAZ" />
                    <Tab label="FIRMA" />
                </Tabs>
                <div className="my-3" hidden={value !== 0} >
                    <div className="my-1">
                        <label htmlFor="">Sunucu Adresi</label>
                        <input type="text" className="w-full border border-gray-300 rounded-md p-2" defaultValue={settings.smtp_server} name="smtp_server" onChange={changeHandler} />
                    </div>
                    <div className="my-1">
                        <label htmlFor="">Port</label>
                        <input type="text" className="w-full border border-gray-300 rounded-md p-2" defaultValue={settings.smtp_port} name="smtp_port" onChange={changeHandler} />
                    </div>
                    <div className="my-1">
                        <label htmlFor="">Kullanıcı Adı</label>
                        <input type="text" className="w-full border border-gray-300 rounded-md p-2" defaultValue={settings.smtpUser} name="smtpUser" onChange={changeHandler} />
                    </div>
                    <div className="my-1">
                        <label htmlFor="">Şifre</label>
                        <input type="text" className="w-full border border-gray-300 rounded-md p-2" defaultValue={settings.smtpPassword} name="smtpPassword" onChange={changeHandler} />
                    </div>
                    <div className="my-1">
                        <label htmlFor="">Şifreleme Methodu</label>
                        <select className="w-full border border-gray-300 rounded-md p-2" defaultValue={settings.smtpMethod} name="smtpMethod" onChange={changeHandler}>
                            <option value="">Yok</option>
                            <option value="sll">SSL</option>
                            <option value="tsl">TLS</option>
                        </select>
                    </div>
                </div>
                <div className="my-3" hidden={value !== 1} >
                    <div className="my-1">
                        <label htmlFor="">Mesai için kullanıcıdan foto alma:</label>
                        <Switch
                            checked={settings.disable_take_photo}
                            name="disable_take_photo"
                            onChange={(e) => { changeHandler(e, e.target.checked); setdisable_take_photo(e.target.checked) }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                    <div className="my-1">
                        <label htmlFor="">Günlük geç kalma toleransı (dk)</label>
                        <input type="text" className="w-full border border-gray-300 rounded-md p-2" defaultValue={settings.gec_kalma_zaman_asimi} name="gec_kalma_zaman_asimi" onChange={changeHandler} />
                    </div>
                    <div className="my-1">
                        <label htmlFor="">Tarayıcıdan mesai başlatmak için IP kontrolü yapma:</label>
                        <Switch
                            checked={settings.browserIpControlByPass}
                            name="browserIpControlByPass"
                            onChange={(e) => { changeHandler(e, e.target.checked); setBrowserIpControlByPass(e.target.checked) }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>

                    <div className={`${browserIpControlByPass ? 'hidden' : ''} my-1`}>
                        <label htmlFor="">Kullanıcı Grubu Kısıtlana IP 'leri (Tüm personeller)</label>
                        <input type="text" className="w-full border border-gray-300 rounded-md p-2" defaultValue={settings.gec_kalma_zaman_asimi} name="gec_kalma_zaman_asimi" onChange={changeHandler} />
                    </div>
                </div>
                <div className="my-3" hidden={value !== 2} >
                    <div className="my-1">
                        <label htmlFor="">Mesai takip cihazlarının aktifliğini izle:</label>
                        <Switch
                            checked={settings.cihaz_izleme}
                            name="cihaz_izleme"
                            onChange={(e) => { changeHandler(e, e.target.checked); setcihaz_izleme(e.target.checked) }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                    <div className={`${cihaz_izleme ? '' : 'hidden'} my-1`}>
                        <label htmlFor="">Bildirim Maili Gönderilecek Adresler (birden çok adres için virgün ile ayırabilirsiniz):</label>
                        <input type="text" className="w-full border border-gray-300 rounded-md p-2" defaultValue={settings.cihaz_izleme_e_postalar} name="cihaz_izleme_e_postalar" onChange={changeHandler} />
                    </div>
                </div>
                <div className="my-3" hidden={value !== 3} >
                    <div className="my-1">
                        <label htmlFor="">Firma Logo</label>
                        <input type="text" className="w-full border border-gray-300 rounded-md p-2" defaultValue={settings.firma_logo_url} name="firma_logo_url" onChange={changeHandler} />
                    </div>
                    <div className="my-1">
                        <label htmlFor="">Web Sitesi Başlık</label>
                        <input type="text" className="w-full border border-gray-300 rounded-md p-2" defaultValue={settings.site_baslik} name="site_baslik" onChange={changeHandler} />
                    </div>
                </div>
                <div className="my-3 flex flex-row justify-end">
                    <button className="bg-slate-500 px-4 text-white py-2 rounded" onClick={saveData}>Kaydet</button>
                </div>
            </div>
        </Dialog>
    )
}
