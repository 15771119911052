import './App.css';
import {RouterProvider} from "react-router-dom";
import router from "./router";
import Aside from './layout/Aside';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useEffect} from "react";

function App() {
    useEffect(() => {

        document.title = JSON.parse(localStorage.getItem('userData')).firma_ayar.site_baslik ?? "PDKS";
        let headTitle = document.querySelector('head');
        let favicon = document.querySelector('link[rel="icon"]');
        headTitle.removeChild(favicon);
        let setFavicon = document.createElement('link');
        setFavicon.setAttribute('rel','shortcut icon');
        setFavicon.setAttribute('href',JSON.parse(localStorage.getItem('userData')).firma_ayar.firma_logo_url);
        headTitle.appendChild(setFavicon);
    }, [])
    const setYetki = (yetki) => {
        localStorage.setItem('yetki',yetki)
        window.location.reload()
    }
  return (
    <div className="flex flex-row">
        <Aside />
      <div className="content-style">
          <RouterProvider router={router} />
      </div>
        <ToastContainer  position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover theme="light"/>
    </div>
  );
}

export default App;
