import axios from "axios";
import {toast} from "react-toastify";
//axios.defaults.baseURL = 'https://bulut.kuvarssoft.com/api/v1';

axios.defaults.baseURL = process.env.NODE_ENV === "development"?'https://bulut.kuvarssoft.com/api/v1':  'https://bulut.kuvarssoft.com/api/v1';

const userData=localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')):undefined
const token =localStorage.getItem('token')?localStorage.getItem('token'):undefined

const list = async (table_name, params) => {
    if (userData === undefined) return;

    params["column_array_id"] = userData.auths.tables[table_name]?.lists?.[0];
    params["column_array_id_query"] = userData.auths.tables[table_name]?.queries?.[0] ?? userData.auths.tables[table_name]?.lists?.[0];

    return await axios
        .post(token + "/tables/" + table_name, {
            params: JSON.stringify(params),
        }).then(res=>{
            if(res.data.data.message ==='fail.token'){
                toast.error('Oturumunuz sonlandı. Lütfen tekrar giriş yapın.')
                localStorage.removeItem('token')
                localStorage.removeItem('userData')
                window.location.href='/login'
            }
            if(res.data.status ==='error'){
                if(table_name ==="departments" && res.data.data.message ==="no.param.column_array_id"){

                }
                else toast.error(res.data.data.message)
            }
            return res

        })
        .catch((err) => {
            console.error(err.response);
        });
};
const edit = async (table_name, id) => {
    const params = {
        column_set_id: userData.auths.tables[table_name]?.edits[0],
    };
    return await axios
        .post(token + "/tables/" + table_name + "/" + id + "/edit", {
            params: JSON.stringify(params),
        })
        .catch((err) => {
            console.error(err.response);
        });
};
const update = async (table_name, id, formData) => {
    formData.append("column_set_id",userData.auths.tables[table_name].edits[0])
    return await axios.post(token + "/tables/" + table_name + "/" + id + "/update", formData).catch((err) => {
        console.error(err.response);
    });
};
const create = async (table_name) => {
    const params = {
        column_set_id: userData.auths.tables[table_name].creates[0],
    };
    return await axios
        .post(token + "/tables/" + table_name + "/create", {
            params: JSON.stringify(params),
        })
        .catch((err) => {
            console.error(err.response);
        });
};
const add = async (table_name, formData) => {
    formData.append("column_set_id",userData.auths.tables[table_name].creates[0])

    return await axios.post(token + "/tables/" + table_name + "/store", formData).catch((err) => {
        console.error(err.response);
    });
};
const show = async (table_name, id) => {

    const params = {
        column_set_id: userData.auths.tables[table_name].shows?.[0],
    };
    return await axios
        .post(token + "/tables/" + table_name + "/" + id, {
            params: JSON.stringify(params),
        })
        .catch((err) => {
            console.error(err.response);
        });
};
const deleted = async (table_name, id) => {
    return await axios.post(token + "/tables/" + table_name + "/" + id + "/delete").catch((err) => {
        console.error(err.response);
    });
};
const columnData = async (table_name, column_name) => {
    return await axios.post(token + "/tables/" + table_name + "/getSelectColumnData/" + column_name ,{search:"***"}).catch((err) => {
        console.error(err.response);
    });
};

const konumIleMesaiBaslat = async (loc) => {
    return await axios.post(token + "/konumlaMesaiBaslat?point="+loc).catch((err) => {
        console.error(err.response);
    });
}
const tarayiciIleMesaiBaslat = async () => {
    return await axios.post(token + "/browserMesaiKontrol").catch((err) => {
        console.error(err.response);
    });
}
const qrIleMesaiBaslat = async (qr) => {
    return await axios.post(token + "/karekodIleMesaiBaslat?karekod="+qr).catch((err) => {
        console.error(err.response);
    });
}
const tagIleMesaiBaslat = async (tag) => {
    return await axios.post(token + "/tagIleMesaiBaslat?tag="+tag).catch((err) => {
        console.error(err.response);
    });
}
const sendPhotoViaUser = async (params) => {
    return await axios.post(token + "/sendPhotoViaUser", params).catch((err) => {
        console.error(err.response);
    });
}
const login = async (params) => {
    return await axios.post("login", params)
        .then((res) => {

            if(res.data.data.token){
                localStorage.setItem('token',res.data.data.token)
                toast.success("Giriş başarılı")
                getUserData(res.data.data.token).then(()=>{
                    window.location.href="/"
                })
            }
            else if(res.data.data.message ==='user.locked'){
                toast.error("Kullanıcı kilitlendi")
            }
            else{
                toast.error("Kullanıcı adı veya şifre hatalı")
            }
        })
        .catch((err) => {

            toast.error("Sunucuya bağlanırken bir hata oluştu.")
            console.error(err.response);
        });
}
const getUserData = async (token) => {
    return await axios.post(token + "/getLoggedInUserInfo")
        .then((res) => {

            if(res.data.data){
                localStorage.setItem('userData',JSON.stringify(res.data.data))
                localStorage.setItem('ye',res.data.data.yetki)
                if(res.data.data.yetki === "[\"116\", \"117\", \"62\", \"121\"]"){
                    localStorage.setItem('yetki',0)
                }else if (res.data.data.yetki ===  "[\"104\"]"){
                    localStorage.setItem('yetki',1)
                }else if (res.data.data.yetki === "[\"89\"]"){
                    localStorage.setItem('yetki',2)
                }else if (res.data.data.yetki === "[\"72\", \"113\", \"74\", \"112\"]") {
                    localStorage.setItem('yetki', 3)
                }else if(res.data.data.yetki ===  "[\"77\", \"86\", \"111\", \"75\", \"76\", \"73\", \"79\", \"78\", \"85\", \"110\"]"){
                    localStorage.setItem('yetki',4)
                }
                else if(res.data.data.yetki ==="[\"120\"]"){
                    localStorage.setItem('yetki',5)
                }
            }
        })
        .catch((err) => {
            console.error(err.response);
        });
}

const getDashboardData = async (name) => {
    return await axios.post(token + "/dashboards/getData/dashboards:"+name, {})
        .catch((err) => {
            console.error(err.response);
        });
}
const iceriAktar = async (file) => {
    const formData = new FormData();
    formData.append("usersListFile", file);
    return await axios.post(token + "/usersListUpload", formData)
        .then((res) => {
            if(res.data.status === "success"){
                toast.success("İçeri aktarma başarılı")
            }else{
                toast.error(res.data.data.message)
            }
        })
        .catch((err) => {
            console.error(err.response);
            toast.error("İçeri aktarma başarısız")
        });
}
const turGorevDataGetir = async (id) => {
    return await axios.post(token + "/turGorevDataGetir/"+id, {})
        .catch((err) => {
            console.error(err.response);
        });
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list,
    show,
    edit,
    update,
    create,
    add,
    deleted,
    konumIleMesaiBaslat,
    tarayiciIleMesaiBaslat,
    qrIleMesaiBaslat,
    tagIleMesaiBaslat,
    sendPhotoViaUser,
    columnData,
    login,
    getDashboardData,
    iceriAktar,
    turGorevDataGetir
};
