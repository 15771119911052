import React, {useEffect, useState} from 'react'
import request from "../../request";
import {FiUser} from "react-icons/fi";
export default function MudurluklerDurumLine() {
   const [series, setSeries] = useState([])
    const getSeries = () => {
        request.getDashboardData("RecordCount:users:0").then((res) => {
            setSeries(res.data.data)

        })
    }
    useEffect(() => {
        getSeries()
    }, [])
    return (
        <div className="flex flex-row items-center bg-slate-500 p-3 rounded-full">
            <FiUser className="text-xl text-gray-200"/>
            <span className="text-xl ml-3 text-white">Toplam Personel sayısı:</span>
            <span className="text-xl ml-5 bg-slate-200 px-2 rounded-full text-black">{series.count}</span>
        </div>

    )

}
