import React, { useEffect, useState } from 'react'
import request from "../../request";
import {Autocomplete} from "@mui/material";
import TextField from "@mui/material/TextField";
export default function EnCokCalisan() {
    const [series, setSeries] = useState([])
    const [options, setOptions] = useState([])

    const [loading, setLoading] = useState(true)
    const getSeries = (kurum) => {
        setLoading(true)
        request.getDashboardData("ComboBoxList:Mesai:EnCokCalisan?type="+kurum).then((res) => {
            setOptions([])
            setSeries(res.data.data.data)
            let _options = []
            res.data.data.combobox && res.data.data.combobox.map((item) => {
                console.log(item)
                _options.push({label: item.display, value: item.source})
            })
            setOptions(_options)
            setLoading(false)
        })
    }
    const setValue = (e) => {
        getSeries(e?.value)
    }
    useEffect(() => {
        getSeries()
    }, [])
    return (
        <div className="flex flex-col  rounded">
            <div className="flex flex-col items-center justify-between">
                <span className="text-xl w-full mb-3 pb-2 border-b">En Çok Çalışan </span>
                {options && options.length>0 && <Autocomplete
                    className="w-full"
                    disablePortal

                    options={options}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    onChange={(e,v) => {setValue(v)}}
                    renderInput={(params) => <TextField {...params} label={"Müdürlükler"} classes={{root:"text-2xl"}} />} />
                }
            </div>
            {loading ?
                <div className="flex flex-row justify-center items-center h-80">
                    <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-slate-700"></div>
                </div>
                :
                <div className=" flex flex-col max-h-80 overflow-auto">
                    {series.length>0 ?
                        series.map((item, index) => {
                            return (
                                <div key={index} className="comboboxlist" dangerouslySetInnerHTML={{__html: item.html}}></div>
                            )
                        })
                        :
                        <div className="flex flex-col items-center justify-center h-96">
                            <span className="text-2xl">Veri Bulunamadı</span>
                        </div>
                    }

                </div>
            }

        </div>
    )

}
