import Inputs from "../components/Inputs";
import {useEffect, useState} from "react";
import request from "../request";
import {MdOutlineAddLocationAlt} from "react-icons/md";
import Sorular from "../widget/Bekci/Sorular";
import Noktalar from "../widget/Bekci/Noktalar";
import {toast} from "react-toastify";
export default function Bekci({editId,setVisible}) {
    const [columns, setColumns] = useState({
        name_basic: undefined
    });
    const [params, setParams] = useState({
        tur_nokta_data:'[]',
    });
    const [loading, setLoading] = useState(true);
    const [noktaVisible, setNoktaVisible] = useState(false);
    const [noktaEditId, setNoktaEditId] = useState(0);
    useEffect(() => {
        getCreateForm()
    }, []);
    const getCreateForm = () => {
        setLoading(true)
        request.create('tur_rotalar').then((res) => {
            setColumns(res.data.data.column_set.column_arrays[0].columns);
            setLoading(false)
        })
    }
    const getEditForm = () => {
        setLoading(true)
        request.edit('tur_rotalar',editId).then((res) => {
            setColumns(res.data.data.column_set.column_arrays[0].columns);
            setParams(res.data.data.record)
            setInterval(() => {
                setLoading(false)
            },1000)
        })
    }
    const onChange = (e) => {
        setParams({...params,[e.target.name]:e.target.value})
    }
    const onChangeNokta = (e) => {
        setLoading(true)
        let _params = params
        _params.tur_nokta_ids = JSON.parse(_params.tur_nokta_ids || '[]')
        _params.tur_nokta_ids.push(e)
        _params.tur_nokta_ids = JSON.stringify(_params.tur_nokta_ids)
        setParams(_params);

        setTimeout(() => {
            setLoading(false)
        },500)
    }
    const saveData = () => {
        const formData = new FormData();
        formData.append("name_basic",params.name_basic)
        formData.append("description",params.description)
        formData.append("tur_nokta_ids",params.tur_nokta_ids)
        formData.append("tur_nokta_data",JSON.stringify(params.tur_nokta_data))
        if(editId){
            request.edit('tur_rotalar',editId,formData).then((res) => {
                if(res.data.data.message === 'success'){
                    toast.success('Başarıyla Güncellendi')
                    setVisible(false)
                }else{
                    toast.error('Hata Oluştu')
                }
            })
        }

        else {
            request.add('tur_rotalar', formData).then((res) => {
                if (res.data.data.message === 'success') {
                    toast.success('Başarıyla Eklendi')
                    setVisible(false)
                } else {
                    toast.error('Hata Oluştu')
                }
            })
        }
    }
    useEffect(() => {
        if(editId) getEditForm()
        else getCreateForm()
    }, []);
    return(
        <div className={" w-full "}>
            <div className={"p-5 bg-white rounded-xl flex flex-col"}>
                <span className="text-xl border-b pb-2 mb-3 w-full">Bekçi Rotası</span>
                {!loading ?
                    <div className={"flex flex-col "}>
                        <Inputs className={"mb-3"} column={columns.name_basic} tableName={'tur_rotalar'} params={params} changeHandler={onChange} />
                        <div className={"flex flex-row"}>
                            <Inputs className={"mb-3 w-full"} column={columns.tur_nokta_ids} tableName={'tur_rotalar'} params={params} changeHandler={onChange} />
                            <button className={"h-14 w-14 ml-2 rounded bg-sky-700 hover:bg-sky-600 text-3xl text-white flex flex-row items-center justify-center"}
                                    onClick={()=> {setNoktaEditId(0); setNoktaVisible(true)}}>
                                <MdOutlineAddLocationAlt />
                            </button>
                        </div>
                        <Inputs className={"mb-3"} column={columns.description} tableName={'tur_rotalar'} params={params} changeHandler={onChange} />
                        <Sorular sorular={JSON.parse(params.tur_nokta_data || '[]') } changeSorular={(v)=> onChange({target:{name:'tur_nokta_data',value:JSON.stringify(v)}})} />
                    </div>
                    :
                    <div className="flex justify-center items-center h-96">
                        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
                    </div>
                }
                <div className={"flex flex-row justify-end mt-3"}>
                    <button className={"p-2 px-3 bg-green-500 text-white rounded"} onClick={saveData}>Kaydet</button>
                </div>

            </div>
            <Noktalar open={noktaVisible} setOpen={setNoktaVisible} editId={noktaEditId} saveId={onChangeNokta} />
        </div>
    )
}
/*
<div className={"flex flex-row items-center mb-3"}>
                            <span className={"mr-3"}>Noktalar:</span>
                            {params.tur_nokta_ids ? params.tur_nokta_ids.map((item) => {
                                return(
                                   <div className={"  rounded border flex flex-col items-center p-2 mr-3"}>
                                       <span>Nokta {item}</span>
                                       <button className={"mt-2  flex items-center bg-green-100 p-2 rounded"}
                                               onClick={()=>{setNoktaEditId(item); setNoktaVisible(true)}}>
                                           <MdOutlineEditLocation className={"text-xl"} />
                                           Düzenle
                                       </button>
                                       <button className={"mt-2 w-full justify-center flex items-center bg-red-100 p-2 rounded"}
                                               onClick={()=>{setNoktaEditId(item); setNoktaVisible(true)}}>
                                           <MdOutlineWrongLocation className={"text-xl"} />
                                           Kaldır
                                       </button>
                                   </div>
                                )
                            }) : null}

                        </div>
 */


