import {CiImport} from "react-icons/ci";
import React from "react";
import request from "../../request";

export default function IceriAktar() {
    const changeEvent = (e) => {
        request.iceriAktar(e.target.files[0]).then((res) => {
            e.target.files = null
        })
    }
    return(
        <div>
            <label className="custom-file-upload bg-slate-700 flex items-center p-2 text-base font-normal text-gray-900 rounded-lg   hover:bg-slate-500/70">
                <input type="file" className="hidden" onChange={changeEvent}/>
                <div className="flex"><CiImport className="text-2xl text-white"/></div>
                <span className="ml-3 text-white">İçeri aktar</span>
            </label>
        </div>
    )
}
