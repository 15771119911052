import React from 'react';
import Dialog from '@mui/material/Dialog';
import MultiSelect from "../widget/Detail/MultiSelect";
import MesaiGecisDetayi from "../widget/Tables/MesaiGecisDetayi";
import CihazBenzersizDatasi from "../widget/Tables/CihazBenzersizDatasi";
import Alan from "../widget/Tables/Alan";
import GirisCikisDurumu from "../widget/Tables/GirisCikisDurumu";
import Basarili from "../widget/Tables/Basarili";
import RotaWkt from "../widget/Tables/RotaWkt";
import DateTime from "../widget/Tables/DateTime";
import Files from "../widget/Tables/Files";
import Boolean from "../widget/Tables/Boolean";
import TurData from "../widget/Tables/TurData";
export default function Detail({data,columns,visible,setVisible}) {

    return(
        <Dialog open={visible} onClose={() => setVisible(false)}>
            <div className="flex flex-col p-3 ">
                <div className="flex flex-data justify-between">
                    <div className="font-bold text-xl">Detaylar</div>
                    <div className="cursor-pointer text-xl text-red-600" onClick={() => setVisible(false)}>X</div>
                </div>
                <div className="border-b-2 border-gray-100 my-2"></div>
                <div className="flex flex-col">
                    {!!columns && !!data &&
                        Object.values(columns).map((column) => (
                            <div   className="grid grid-cols-4 mb-2 pb-1 border-b border-gray-100">
                                <div className="col-span-1 font-bold">
                                    {column.display_name}:
                                </div>
                                <div className="col-span-3">
                                    <>
                                        {(()=>{
                                            if(column.name === 'mesai_gecis_detayi') return <MesaiGecisDetayi datas={data}/>
                                            else if(column.name === 'device_unique_info') return <CihazBenzersizDatasi datas={data}/>
                                            else if(column.name === 'alan') return <Alan datas={data[column.name]}/>
                                            else if(column.name === 'giris_durumu' || column.name === 'cikis_durumu') return <GirisCikisDurumu datas={data[column.name]}/>
                                            else if(column.name === 'basarili') return <Basarili datas={data[column.name]}/>
                                            else if(column.name === 'rota_wkt') return <RotaWkt datas={data}/>
                                            else if(['tur_rota_data','tur_nokta_data'].includes(column.name)) return <TurData datas={data[column.name]}/>
                                            else if(column.gui_type_name === 'datetime') return <DateTime datas={data[column.name]}/>
                                            else if(column.gui_type_name === 'files') return <Files datas={data[column.name]}/>
                                            else if(column.gui_type_name === 'multiselect' || column.gui_type_name === 'multiselectdragdrop') return <MultiSelect datas={data[column.name]}/>
                                            else if(['boolean','boolean:fastchange'].includes(column.gui_type_name)) return <Boolean datas={data[column.name]}/>
                                            /*else if(column.gui_type_name === 'text') return <Text datas={data[column.name]}/>*/

                                            else{
                                                return(
                                                    <div className={" font-medium text-gray-900 "}>
                                                        <span className={ " text-gray-600 ellipsis-2"}>
                                                             {data[column.name]}
                                                        </span>

                                                    </div>
                                                )
                                            }
                                        })()}
                                    </>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </Dialog>
    )
}
