//import { QrReader } from 'react-qr-reader';
import QrReader from 'modern-react-qr-reader'
import Dialog from '@mui/material/Dialog';
import {AiOutlineCloseCircle} from "react-icons/ai";
import request from "../../request";
import {toast} from "react-toastify";
import {MdCameraswitch} from "react-icons/md";
import {useEffect, useState,useRef} from "react";

export default function QRCode({visible,setVisible}) {
    const qrRef = useRef(null)
    const [cameraMode,setCameraMode] = useState("user")
    const [loading,setLoading] = useState(false)
    const handleScan = (data) => {

        if (data) {
            setLoading(true)
            toast.dismiss()
            request.qrIleMesaiBaslat(data).then((res)=>{
                if(res.data.status === 'success'){

                    toast.success("QR ile mesai başlatıldı.")
                    setVisible(false)
                }else{
                    toast.error(res.data.data.message)
                }
            })

        }
    }
    const handleError = (err) => {
        console.error(err)
        toast.error("Bir hata oluştu.")
    }
    useEffect(()=>{
        setLoading(true)
        setTimeout(()=>{
            setLoading(false)
        },100)
    },[cameraMode])

    const closeCamera = async () => {
        console.log("stop",qrRef.current)
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        stream.getTracks().forEach(function(track) {
            console.log("stop",track)
            //todo
            track.stop();
            stream.removeTrack(track);
        });
        window.location.reload()
        // console.log(stream)
        // stream.load();
    }

    return(
        <Dialog onClose={()=>{setVisible(false);closeCamera()}} open={visible}>
            <div className="">
                <div className="py-2 px-4 flex flex-row justify-between">
                    <h1>QR Kod ile mesai başlat</h1>
                    <AiOutlineCloseCircle className="text-2xl text-red-600 cursor-pointer" onClick={()=>{setVisible(false);closeCamera()}}/>
                </div>



                    <div className="w-[400px] max-w-full">
                        {!loading && visible &&
                            <QrReader
                                ref={qrRef}
                                delay={300}
                                facingMode={cameraMode}
                                onError={handleError}
                                onScan={handleScan}
                                style={{ width: '100%' }}
                            />

                        }
                            <button className="bg-sky-500 text-white  py-2 px-4 rounded my-1 ml-2"
                                    onClick={()=> setCameraMode(cameraMode === 'user' ?"environment" :"user")}><MdCameraswitch/></button>
                    </div>


            </div>
        </Dialog>
    )
}
