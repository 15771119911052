import React, {useEffect, useState} from "react";
import Profile from "../widget/aside/Profile";
import Mesai from "../widget/aside/Mesai";
import List from "../widget/aside/List";
import MobileNav from "../widget/aside/MobileNav";
export default function Aside({asideShow}) {
    const [mobileShow, setMobileShow] = useState(false)
    useEffect(() => {
        setMobileShow(asideShow)
    }, [asideShow])

    return(
        <div className=" md:w-[310px] relative">
            <div className={`${mobileShow?'!block':'hidden md:block'} aside bg-slate-700 z-10  w-screen md:w-[310px] h-screen fixed flex flex-col overflow-auto`}>
                <Profile closeAside={()=>[setMobileShow(false)]}/>
                {localStorage.getItem('yetki') !== "1" && localStorage.getItem('yetki') !== "4" && <Mesai  closeAside={()=>[setMobileShow(false)]}/>}

                <List/>

            </div>
            <MobileNav asideShow={mobileShow} updateAsideShow={(e)=>setMobileShow(e)}/>
        </div>
    )
}

