import Dialog from "@mui/material/Dialog";
import {useEffect, useState} from "react";
import request from "../../request";

export default function RotaWkt({datas}){
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if(open){
            request.turGorevDataGetir(datas.id).then(res=>{
            })
        }
    }, [open])
    return (
        <td>
            {/*<div>
                <button onClick={()=>setOpen(true)} className="bg-slate-500 hover:bg-slate-400 text-white px-4 py-2 rounded">Ön izleme</button>
            </div>
            <Dialog open={open} onClose={()=>setOpen(false)}>
                açıldı
            </Dialog>*/}
        </td>
    )
}
