import React, {useEffect, useState} from 'react'
export default function MultiSelect({datas}) {
    const [visible, setVisible] = useState(false)
    const [data, setData] = useState({})
    useEffect(() => {
        setData(JSON.parse(datas))
        document.addEventListener('click', (e) => {
            if(e.target.className.baseVal !== undefined) {
                setVisible(false)
                return;
            }
            if (e.target.className && e.target.className?.search('.detailButton') >-1) return;
            setVisible(false)
        })
    }, [])

    return(
        <td className="flex flex-row items-center flex-wrap">
            <>
                {!!data && Object.values(data).map((item, index) => (
                    <>
                        {item?.display ? <span className="whitespace-nowrap m-1 p-2 bg-slate-100 rounded" key={index}>{item.display}</span> : null}
                    </>
                ))}
            </>

        </td>
    )
}
