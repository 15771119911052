import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import request from "../../request";

export default function Boolean({defaultValue,label,name,onChange}){
    const [value, setValue] = useState(defaultValue?{label:"Evet",value:1}:{label:"Hayır",value:0}  )
    useEffect(() => {

        if (value){
            onChange({
                target: {
                    name: name,
                    value: value.value?1:0
                }
            })
        }else{
            onChange({
                target: {
                    name: name,
                    value: 0
                }
            })
        }

    }, [value])
    return(
        <div className=" flex flex-row">

                    <Autocomplete
                        className="w-full"
                        disablePortal
                        options={[{label:"Evet",value:1},{label:"Hayır",value:0}]}
                        value={value}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(e,v) => {setValue(v)}}
                        renderInput={(params) => <TextField {...params} label={label} classes={{root:"text-2xl"}} />}
                    />



        </div>
    )
}
