import {useEffect, useState} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function Sorular({defaultValue ,name,label, onChange}){

    const [sorularParams, setSorularParams] = useState([]);
    useEffect(()=>{
        setSorularParams(JSON.parse(defaultValue || "[]") || [])
    },[defaultValue])
    const types = [
        {label: "checkbox", value: "checkbox"},
        {label: "text", value: "text"},
        {label: "number", value: "photo"},
    ]
    const validations = [
        {label: "required", value: "required"},
    ]
    const setSorular = (value, clmName, index) => {
        let temp = sorularParams
        temp[index][clmName] = value;
        setSorularParams(temp);
        onChange({
            target:{
                name: name,
                value: JSON.stringify(temp)
            }
        });
    }
    const yeniSoru = () => {
        let temp = sorularParams;
        temp.push({type: "", soru: "", validations: ""})
        setSorularParams(temp);
        onChange({
            target:{
                name: name,
                value: JSON.stringify(temp)
            }
        });
    }
    const soruSil = (index) => {
        let temp = sorularParams;
        temp.splice(index, 1);
        setSorularParams(temp);
        onChange({
            target:{
                name: name,
                value: JSON.stringify(temp)
            }
        });
    }

    return(
        <div className={"p-3 border rounded"}>
            <span>{label}</span>
            <div className={"flex flex-col"}>
                {!!sorularParams && sorularParams.map((item, index) =>{
                    console.log(item, index)
                    return(
                    <div className={"flex flex-row mt-3  border-b pb-2"} key={item} >
                        <button className={"bg-red-600 text-white rounded  py-2 px-3 mr-2 mb-1"} onClick={()=>soruSil(index)}>Sil</button>
                        <div className={"flex flex-row  flex-wrap"}>

                            <Autocomplete
                                className="w-[130px]  mr-2 mb-1"
                                disablePortal
                                disableClearable
                                options={types}
                                value={item.type}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={(e,v) => {setSorular(v.value,'type',index)}}
                                renderInput={(params) => <TextField {...params} label={"Tip"} classes={{root:"text-2xl"}}  />}
                            />
                            <TextField label={"Soru"} value={item.soru} className={"w-[300px] max-w-full !mb-1 !mr-2"} onChange={(e)=> setSorular(e.target.value,'soru',index)} />
                            <Autocomplete
                                className="w-[130px] mr-2 mb-1"
                                disablePortal
                                disableClearable
                                options={validations}
                                value={item.validations}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={(e,v) => {setSorular(v.value,'validations',index)}}
                                renderInput={(params) => <TextField {...params} label={"Doğrulama"} classes={{root:"text-2xl"}} />}
                            />
                        </div>
                    </div>
                    )}
                )}
                <div className={"flex flex-row mt-3 justify-end"}>
                    <button className={"p-2  px-3 bg-sky-300 rounded"} onClick={yeniSoru}> Yeni soru</button>
                </div>

            </div>

        </div>
    )
}
