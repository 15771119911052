import { BsCheck2Circle,BsXCircle } from "react-icons/bs";
export default function Boolean({datas}) {

    return(
        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
            <span className="">
                {datas ?
                    <BsCheck2Circle className="text-green-500 text-xl" />
                    :
                    <BsXCircle className="text-red-500 text-xl" />
                }
            </span>
        </td>
    )
}
