import React, {useEffect, useRef, useState} from 'react';
import { Autocomplete, DrawingManager, GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import proj4 from "proj4";
import {register} from "ol/proj/proj4";
import {toLonLat} from "ol/proj";
import WKT from "ol/format/WKT";
import * as olProj from "ol/proj";

/**
 *
 * @param defaultValue
 * @param name
 * @param onChange = null ,seçilmiş olan veriyi method olarak döndürür.
 * @returns {JSX.Element|null}
 * @constructor
 */
const MapComponent = ({defaultValue,name,onChange}) => {

    const mapRef = useRef();
    const polygonRefs = useRef([]);
    const activePolygonIndex = useRef();
    const autocompleteRef = useRef();
    const drawingManagerRef = useRef();
    const libraries = ['drawing','places'];
    const [polygons, setPolygons] = useState([]);
    useEffect(() => {
        if (defaultValue) {

            proj4.defs('EPSG:7932', '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs');

            register(proj4);
            const format = new WKT();

            const  feature = format.readFeature(defaultValue);
            const coordinates = []
            // eslint-disable-next-line array-callback-return
            feature.getGeometry().getCoordinates()[0].map((item)=>{
                coordinates.push({
                    lat:parseFloat(toLonLat(item, 'EPSG:7932')[1]),
                    lng:parseFloat(toLonLat(item, 'EPSG:7932')[0])
                })
            })

            setPolygons([coordinates])
            setCenter(coordinates[1])

        }
    }, [])
    /**
     * Google Map Api Key
     * Tüm işlemlerde aynı olması gerekiyor yoksa hata alırsınız.
     *
     */
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAlx419IuJAost_y_fMCVPst4c7YKgpnRU',
        libraries
    });

    useEffect(() => {

        if (polygons.length>0) {
            proj4.defs('EPSG:7932', '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs');
            register(proj4);

            let newPolygon = []
            // eslint-disable-next-line array-callback-return
            polygons[0].map((item) => {
                newPolygon.push(olProj.transform([item.lng,item.lat], 'EPSG:4326', 'EPSG:7932'))
            })
            let polyText = "";
            newPolygon.forEach((item,index) => {
                if (index === newPolygon.length-1){
                    polyText += item[0] + " " + item[1] ;
                }else
                    polyText += item[0] + " " + item[1] + ",";

            })
            if(newPolygon[0] !== newPolygon[newPolygon.length-1]){
                polyText += ","+newPolygon[0][0] + " " + newPolygon[0][1];
            }
            setCenter(getCenter(polygons[0]))
            onChange({
                target: {
                    name: name,
                    value: "POLYGON(("+polyText+"))",
                    deger:'polygons use effect'
                }
            })

        }else{
            onChange({
                target: {
                    name: name,
                    value: "",
                    deger:'polygons use effect'
                }
            })
        }
    }, [polygons])




    const defaultCenter = {lat:39.841881,lng: 32.835904}
    const [center, setCenter] = useState(defaultCenter);
    const getCenter = (poly)=> {
        let minX, maxX, minY, maxY;
        poly.forEach((point) => {
            if (minX == null || point.lat < minX) {
                minX = point.lat;
            }
            if (maxX == null || point.lat > maxX) {
                maxX = point.lat;
            }
            if (minY == null || point.lng < minY) {
                minY = point.lng;
            }
            if (maxY == null || point.lng > maxY) {
                maxY = point.lng;
            }
        })
        return {
            lat: (minX + maxX) / 2,
            lng: (minY + maxY) / 2
        }
    }
    const getZoom = (poly) => {
        if(poly){
            let minX, maxX, minY, maxY;
            poly.forEach((point) => {
                if (minX == null || point.lat < minX) {
                    minX = point.lat;
                }
                if (maxX == null || point.lat > maxX) {
                    maxX = point.lat;
                }
                if (minY == null || point.lng < minY) {
                    minY = point.lng;
                }
                if (maxY == null || point.lng > maxY) {
                    maxY = point.lng;
                }
            })
            const deltaX = maxX - minX;
            return Math.min(
                21,
                Math.max(
                    1,
                    Math.floor(
                        Math.log(360 / deltaX) / Math.LN2
                    )
                )
            );
        }else return 2
    }
    const containerStyle = {
        width: '100%',
        height: '400px',
        borderRadius: "10px",
    }

    const autocompleteStyle = {
        boxSizing: 'border-box',
        border: '1px solid transparent',
        width: '240px',
        height: '38px',
        padding: '0 12px',
        borderRadius: '3px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        fontSize: '14px',
        outline: 'none',
        textOverflow: 'ellipses',
        position: 'absolute',
        right: '4%',
        bottom: '15px',
        marginLeft: '-120px',
    }

    const deleteIconStyle = {
        cursor: 'pointer',

        height: '24px',
        width: '24px',
        marginTop: '35px',
        backgroundImage:"url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnqQ28oetf1IHDn-fFWIH2KhXdg7psUXI1QHmulLFlli5bUVd95jf3GdrmFCuWtK8hYmY&usqp=CAU')",
        backgroundRepeat: 'no-repeat',
        backgroundSize:"24px 24px",
        backgroundColor: '#fff',
        position: 'absolute',
        top: "2px",
        left: "10px",
        zIndex: 99999,
        content: 'X',
    }

    const polygonOptions = {
        fillOpacity: 0.3,
        fillColor: '#ff0000',
        strokeColor: '#ff0000',
        strokeWeight: 2,
        draggable: true,
        editable: true
    }

    const drawingManagerOptions = {
        polygonOptions: polygonOptions,
        drawingControl: true,
        drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_LEFT,
            drawingModes: [
                window.google.maps.drawing.OverlayType.POLYGON,
            ],
        },

    }

    const onLoadMap = (map) => {
        mapRef.current = map;
    }

    const onLoadPolygon = (polygon, index) => {
        polygonRefs.current[index] = polygon;

    }

    const onClickPolygon = (index) => {
        activePolygonIndex.current = index;
    }

    const onLoadAutocomplete = (autocomplete) => {
        autocompleteRef.current = autocomplete;
    }

    const onPlaceChanged = () => {
        const { geometry } = autocompleteRef.current.getPlace();
        const bounds = new window.google.maps.LatLngBounds();
        if (geometry.viewport) {
            bounds.union(geometry.viewport);
        } else {
            bounds.extend(geometry.location);
        }
        mapRef.current.fitBounds(bounds);
    }

    const onLoadDrawingManager = drawingManager => {
        drawingManagerRef.current = drawingManager;
    }

    const onOverlayComplete = ($overlayEvent) => {
        drawingManagerRef.current.setDrawingMode(null);
        if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
            onChange({
                target: {
                    name: name,
                    value: "",
                    deger:'onOverlayComplete baslangic'
                }
            })
            const newPolygon = $overlayEvent.overlay.getPath()
                .getArray()
                .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }))

            // start and end point should be same for valid geojson
            const startPoint = newPolygon[0];
            newPolygon.push(startPoint);
            $overlayEvent.overlay?.setMap(null);
            setPolygons([...polygons, newPolygon]);
            onChange({
                target: {
                    name: name,
                    value: newPolygon,
                    deger:'onOverlayComplete son'
                }
            })
        }

    }

    const onDeleteDrawing = () => {

        setPolygons([])
        onChange({
            target: {
                name: name,
                value: "",
                deger:'onDeleteDrawing'
            }
        })
    }

    const onEditPolygon = (index) => {
        const polygonRef = polygonRefs.current[index];

        if (polygonRef) {

            onChange({
                target: {
                    name: name,
                    value: "",
                    deger:'onEditPolygon baslangic'
                }
            })
            const coordinates = polygonRef.getPath()
                .getArray()
                .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));

            const allPolygons = [...polygons];
            allPolygons[index] = coordinates;
            setPolygons(allPolygons)
            onChange({
                target: {
                    name: name,
                    value: allPolygons,
                    "deger":"onEditPolygon son"
                }
            })
        }
    }

    return (
        isLoaded
            ?
            <div className='map-container' style={{ position: 'relative' }}>
                {
                    drawingManagerRef.current
                    &&
                    <div
                        onClick={onDeleteDrawing}
                        title='Delete shape'
                        style={deleteIconStyle}>
                    </div>
                }
                <GoogleMap
                    zoom={getZoom(polygons[0])}
                    center={center}
                    onLoad={onLoadMap}
                    mapContainerStyle={containerStyle}
                    onTilesLoaded={() => setCenter(null)}
                    options={{
                        mapTypeControl: false,
                        streetViewControl: false,
                        fullscreenControl: true,
                        zoomControl: true,
                        clickableIcons: true,
                        gestureHandling: 'greedy',
                        zoomControlOptions: {
                            position: window.google.maps.ControlPosition.LEFT_CENTER,
                        },
                        searchBoxControl: false,
                        searchBoxControlOptions: {
                            position: window.google.maps.ControlPosition.TOP_LEFT,
                        },
                        locationBoxControl: false,


                    }}
                >
                    <DrawingManager
                        onLoad={onLoadDrawingManager}
                        onOverlayComplete={onOverlayComplete}
                        options={drawingManagerOptions}
                        onPolygonComplete={onOverlayComplete}
                    />
                    {
                        polygons.map((iterator, index) => (
                            <>

                            <Polygon
                                key={index}
                                onLoad={(event) => onLoadPolygon(event, index)}
                                onMouseDown={() => onClickPolygon(index)}
                                onMouseUp={() => onEditPolygon(index)}
                                onDragEnd={() => onEditPolygon(index)}
                                options={polygonOptions}
                                paths={iterator}
                                draggable
                                editable
                            />
                            </>
                        ))
                    }
                    <Autocomplete
                        onLoad={onLoadAutocomplete}
                        onPlaceChanged={onPlaceChanged}
                    >
                        <input
                            type='text'
                            placeholder='Search Location'
                            style={autocompleteStyle}
                        />
                    </Autocomplete>
                </GoogleMap>
            </div>
            :
            null
    );
}

export default MapComponent;
