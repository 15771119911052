import React, {useEffect, useState} from 'react'
import Dialog from "@mui/material/Dialog";
import {BsFileEarmarkPdf} from "react-icons/bs";
export default function Files({datas}) {
    const [zoom, setZoom] = useState(undefined)
    const [data, setData] = useState({})
    useEffect(() => {
        setData(JSON.parse(datas))
    }, [datas])
    const getFileUzanti = (fileName) => {
        const myArray = fileName.split(".");
        const uzanti = myArray[myArray.length-1];
        return uzanti;
    }

    return(
        <td >
            <div className="max-h-20 overflow-auto flex">
            {
                !!data && data?.length>0 && data.map((item, index) => {
                    return(
                        <>
                        <div key={index} onClick={()=>setZoom(item)}>

                            {getFileUzanti(item.file_name) == 'pdf' && <BsFileEarmarkPdf className="w-12 h-12 rounded p-2" />}

                            {(getFileUzanti(item.file_name) == 'png' || getFileUzanti(item.file_name) == 'jpg' || getFileUzanti(item.file_name) == 'jpeg' ) &&
                                <img src={'https://bulut.kuvarssoft.com/uploads/'+ item.destination_path + item.file_name} className="w-12 h-12 rounded"  alt={"Logo"}/>
                            }
                        </div>
                        <Dialog open={zoom !== undefined} onClose={()=>setZoom(undefined)}>
                            {getFileUzanti(item.file_name) == 'pdf' && <BsFileEarmarkPdf className="w-12 h-12 rounded p-2" />}
                            {
                                zoom &&
                                <a href={'https://bulut.kuvarssoft.com/uploads/'+ zoom.destination_path + zoom.file_name} target="_blank" rel="noreferrer" className="text-sky-500 p-3 text-center">
                                    {'https://bulut.kuvarssoft.com/uploads/'+ zoom.destination_path + zoom.file_name}
                                </a>
                            }
                            {((getFileUzanti(item.file_name) == 'png' || getFileUzanti(item.file_name) == 'jpg' || getFileUzanti(item.file_name) == 'jpeg' ) && zoom !== undefined ) &&
                                <div className="flex flex-col">
                                    <img src={'https://bulut.kuvarssoft.com/uploads/'+ zoom.destination_path + zoom.file_name} className="max-w-[70vw] max-h-[70vh] rounded "  alt={"Logo"}/>
                                </div>
                            }
                        </Dialog>
                </>
                    )
                })
            }
            </div>
        </td>
    )
}
