import {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import TableColumns from "./TableColumns";
import Detail from "./Detail";
import {CiEdit,CiTrash} from "react-icons/ci";
import {BiDetail} from "react-icons/bi";
import {BsArrowDownUp,BsArrowDown,BsArrowUp} from "react-icons/bs";
import request from "../request";
import {toast} from "react-toastify";

import Dual from "./Dual";
import Inputs from "./Inputs";

export default function Tables({datas,refreshData,params,setParams,filtrele}) {
     const [data, setData] = useState([])
    const [columns, setColumns] = useState({})
    const [editVisible, setEditVisible] = useState(false)
    const [deleteVisible, setDeleteVisible] = useState(0)
    const [editID, setEditID] = useState(null)
    const [detailVisible, setDetailVisible] = useState(false)
    const [detailData, setDetailData] = useState(null)
    const [filters, setFilters] = useState({})
    const {tableName} = useParams();
    const auths = JSON.parse(localStorage.getItem('userData')).auths.tables[tableName]
    useEffect(() => {
            setData(datas.records)
            setColumns(datas.columns)
    }, [datas])
    useEffect(() => {
        let _filters = {}
        for (const [key, value] of Object.entries(params.filters)) {
            if (value !== "" && value !== null && value !== undefined){
                _filters[key] = value.filter
            }
        }
        setFilters(_filters)
    }, [filtrele,params])
    const deleteData = (id) => {
        request.deleted(tableName,id).then((res) => {
            if (res.data.data.message === "success"){
                toast.success("Silindi")
                refreshData()
            }else{
                for (const oElement of Object.values(res.data.data.errors)  ) {
                    toast.error(oElement[0])
                }
            }
        }).catch(() => {
            toast.error("Hata Oluştu")
        })
    }

    document.addEventListener('click', (e) => {
        if(e.target.className.baseVal !== undefined){
            if(e.target.className.baseVal && e.target.className.baseVal?.search('.deleteBox') >-1) return;

        }else{
            if (e.target.className && e.target.className?.search('.deleteBox') >-1) return;
            setDeleteVisible(0)

        }
    })

    const setSorts = (name) => {
        let _sorts = params.sorts
        if (_sorts[name] === undefined){
            _sorts[name] = true
        }else if(_sorts[name]){
            _sorts[name] = false
        }else{
            delete _sorts[name]
        }
        setParams({...params,sorts:_sorts})
    }

    const changeHandler = (e) => {
        setFilters({...filters,[e.target.name]:e.target.value})
    }
    const keyPressHandler = (e) => {
        if(e.key === 'Enter'){
            filtreleEvent()
        }
    }
    const filtreleEvent = () => {
        console.log("ara",filters)
        let _filters = {}
        for (const [key, value] of Object.entries(filters)) {
            if(value !== "" && value !== null && value !== undefined && columns[key].gui_type_name.split(':')[0] ==='multiselectdragdrop'){
                _filters[key] = {
                    type:1,
                    guiType:columns[key].gui_type_name.split(':')[0],
                    filter:value
                }
            }
            else if (value !== "" && value !== null && value !== undefined){

                let _value = columns[key].gui_type_name.split(':')[0] ==='select' ? [value] : value
                _filters[key] = {
                    type:1,
                    guiType:columns[key].gui_type_name.split(':')[0],
                    filter:_value
                }
            }else {
                delete _filters[key]
            }
        }
        console.log('params filters',_filters)
        setParams({...params,filters:_filters})
    }
    return(
        <div className="relative overflow-auto h-[78vh] shadow-md sm:rounded-lg relative ">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700  bg-gray-100 sticky top-0">
                <tr>
                    {!!columns && Object.values(columns).map((column) => (
                        <th scope="col" className="px-6 py-3" key={column.name}>
                            <div className="flex flex-row items-center justify-between cursor-pointer" onClick={()=>setSorts(column.name)}>
                                {column.display_name}
                                {params.sorts[column.name] === undefined && <BsArrowDownUp className="text-xs"/>}
                                {params.sorts[column.name] === true && <BsArrowUp className="text-sky-600 text-lg"/>}
                                {params.sorts[column.name] === false && <BsArrowDown className="text-sky-600 text-lg"/>}
                            </div>
                        </th>
                    ))}
                    <th scope="col" className="px-6 py-3">
                        <span className="">İşlemler</span>
                    </th>
                </tr>
                {
                    filtrele &&
                    <tr className="relative h-full">
                        {!!columns && Object.values(columns).map((column) => (
                            <th scope="col" className="min-w-[250px] px-2" key={column.name}>

                                    <Inputs type={"filters"} column={column} params={filters} tableName={tableName} changeHandler={changeHandler} keyPressHandler={keyPressHandler} />

                            </th>
                        ))}
                        <th className="sticky right-0 bottom-0 z-10 flex flex-row justify-end w-fit h-full items-center">
                            <div className="bg-white p-4 h-full">
                                <button className="bg-sky-600 text-white p-3 text  rounded-md" onClick={()=>filtreleEvent()}>Ara</button>
                            </div>
                        </th>
                    </tr>

                }
                </thead>
                <tbody>
                {!!data && Object.values(data).map((row) => (
                    <tr className="bg-white border-b  hover:bg-gray-200 " key={row.id}>
                        {!!columns && Object.values(columns).map((column) => (
                            <>
                                <TableColumns column={column} row={row} key={column.name} />
                            </>

                        ))}
                        <td className="px-6 py-4 text-right flex flex-row items-center relative">




                                {auths.shows &&
                                    <span className="bg-slate-100 border border-slate-100 text-black p-2  rounded-full hover:bg-slate-200 hover:border-slate-300 cursor-pointer" onClick={()=>{setDetailData(row);setDetailVisible(true)}} >
                                        <BiDetail className="text-2xl"/>
                                    </span>
                                }
                                {auths.edits &&
                                    <span className="bg-slate-100 border border-slate-100 text-black p-2 ml-2 rounded-full hover:bg-slate-200 hover:border-slate-300 cursor-pointer" onClick={()=>{setEditID(row.id);setEditVisible(true)}} >
                                        <CiEdit className="text-2xl"/>
                                    </span>
                                }
                                {auths.delete &&
                                    <>
                                        <span className="bg-red-100 border border-red-100 text-black p-2 rounded-full hover:bg-red-200 hover:border-slate-300 cursor-pointer ml-2 deleteBox" onClick={()=>{setDeleteVisible(row.id)}} >
                                            <CiTrash className="text-2xl deleteBox"/>
                                        </span>

                                        <div className={` ${deleteVisible === row.id ?'':'hidden'} bg-white shadow-lg shadow-gray-500 p-2 border border-gray-300 absolute top-[60px] right-[10px] z-10  rounded deleteBox`}>
                                            Silmek istediğinize emin misiniz?
                                            <div className="w-full flex flex-row mt-3 justify-between deleteBox">

                                                <span className="bg-slate-500 border border-slate-500 text-white py-1 px-3 rounded-lg hover:bg-slate-600 cursor-pointer deleteBox" onClick={()=>{deleteData(row.id)}} >Evet</span>
                                                <span className="hover:bg-slate-200 py-1 px-3 ml-5 rounded-lg text-gray-700 cursor-pointer">Hayır</span>
                                            </div>
                                        </div>
                                    </>
                                }


                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <Dual tableName={tableName} visible={editVisible} setVisible={(e)=>{setEditVisible(e);refreshData();}} id={editID} type={'edit'}/>

            <Detail data={detailData} columns={columns} visible={detailVisible} setVisible={(e)=>{setDetailVisible(e)}}/>

        </div>
    )
}
