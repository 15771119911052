import {HiOutlineDocumentSearch, HiOutlineUserGroup} from "react-icons/hi";
import GecKalmaRaporu from "../GecKalmaRaporu";
import { BsCalendar2Plus, BsCalendarDate} from "react-icons/bs";
import {AiOutlineFieldTime} from "react-icons/ai";
import {RiRestTimeLine} from "react-icons/ri";
import {RxCountdownTimer} from "react-icons/rx";
import {TbReplaceFilled} from "react-icons/tb";
import React from "react";
import UserUpdate from "../../../components/UserUpdate";


export default {
    menu:[
        {
            title:"Rapor",
            icon:<HiOutlineDocumentSearch className="text-2xl text-white"/>,
            show:false,
            sub:[
                {
                    component: <GecKalmaRaporu></GecKalmaRaporu>
                }
            ]
        },
        {
            title:"İnsan Kaynakları",
            icon:<HiOutlineUserGroup  className="text-2xl text-white"/>,
            show:false,
            sub:[
                {
                    component: <UserUpdate></UserUpdate>

                }
            ]
        },
        {
            title:"İzin İşlemleri",
            icon:<AiOutlineFieldTime className="text-2xl text-white"/>,
            show:false,
            sub:[
                {
                    title:"İzinler",
                    icon:<RiRestTimeLine className="text-2xl text-white"/>,
                    link:"/tablolar/personel_izinler"
                },
                {
                    title:"Saatlik İzinler",
                    icon:<RxCountdownTimer className="text-2xl text-white"/>,
                    link:"/tablolar/personel_saatlik_izinler"
                },
            ]
        },
        {
            title:"Mesai İşlemleri",
            icon:<BsCalendarDate  className="text-2xl text-white"/>,
            show:false,
            sub:[

                {
                    title:"Mesai Geçişleri",
                    icon:<TbReplaceFilled className="text-2xl text-white"/>,
                    link:"/tablolar/personel_mesai_gecisleri"
                },
                {
                    title:"Personel Mesaileri",
                    icon:<BsCalendar2Plus className="text-2xl text-white"/>,
                    link:"/tablolar/personel_mesaileri"
                }
            ]

        },

    ]
}
