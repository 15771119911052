import Dialog from "@mui/material/Dialog";
import { CiTimer} from "react-icons/ci";
import React, {useEffect, useState} from "react";
import request from "../../request";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function GecKalmaRaporu(){
    const [open, setOpen] = React.useState(false);
    const [departments, setDepartments] = useState([]);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(),
        endDate: new Date(),
    });
    const [filterDepartment, setFilterDepartment] = useState("");


    const token = localStorage.getItem("token");
    const auths =localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData'))?.auths?.tables?.personel_mesaileri:undefined;


    const raporAl = () => {
        const params={
            page:1,
            limit:1000,
            column_array_id:auths?.lists?.[0],
            column_array_id_query:auths?.queries?.[0],
            sorts:{},
            filters:{
                mesai_baslama_zamani:{
                    type:4,
                    guiType:"datetime",
                    filter:filterDate.startDate+ " 00:00:00",
                    filter2:filterDate.endDate +" 23:59:59"
                },
                mudurluk_adi:{
                    type:1,
                    guiType:"string",
                    filter:filterDepartment
                }
            },
            report_format:"xlsx",
            record_id:0,
            report_id:1
        }
        const url=`https://bulut.kuvarssoft.com/api/v1/${token}/tables/personel_mesaileri/report?params=`+JSON.stringify(params)
        window.open(url);
    }
    useEffect(() => {
        if(open){
            const userData=localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')):undefined
            const params={
                page:1,
                limit:1000,
                column_array_id:userData.auths.tables['departments']?.lists?.[0],
                column_array_id_query:userData.auths.tables['departments']?.queries?.[0] ,
                sorts:{},
                filters:{},
            }
            request.list("departments",params).then((res) => {
                setDepartments(res.data.data.records.map((item) => ({ value: item.id, label: item.name_basic })));
            }).catch((err) => {
                console.log(err);
            })
        }

    }, [open]);
    return(
        <div>
            <label onClick={()=>setOpen(true)} className=" bg-slate-700 flex items-center p-2 text-base font-normal text-gray-900 rounded-lg   hover:bg-slate-500/70">
                <div className="flex"><CiTimer className="text-2xl text-white"/></div>
                <span className="ml-3 text-white">Geç Kalma Raporu</span>
            </label>
            <Dialog open={open} onClose={()=>{setOpen(false)}}>
                <div className="flex flex-row  items-center justify-center ">
                    <div className="shadow-2xl p-5 rounded-xl w-[400px]">
                        <h1 className="my-3 text-2xl">Personel Rapor</h1>
                        {departments.length>0 && <Autocomplete
                            disablePortal
                            options={departments}
                            onChange={(e,v) => {setFilterDepartment(v.value)}}
                            renderInput={(params) => <TextField {...params} label="Müdürlük:" />}
                        />}
                        <div className="flex flex-row justify-between items-center my-3" >
                            <div className="my-2 mt-3 relative">
                                <label className="bg-white absolute top-[-10px] text-sm left-[10px] px-1">Başlama Tarihi:</label>
                                <input type="date" className="border border-gray-300 hover:border-gray-600 rounded p-3 w-full block"
                                       onChange={(e) => {let data = filterDate; data.startDate = e.target.value; setFilterDate(data);}} />
                            </div>
                            <div>-</div>
                            <div className="my-2 mt-3 relative">
                                <label className="bg-white absolute top-[-10px] text-sm left-[10px] px-1">Bitiş Tarihi:</label>
                                <input type="date" className="border border-gray-300 hover:border-gray-600 rounded p-3 w-full block"
                                       onChange={(e) => {let data = filterDate; data.endDate = e.target.value; setFilterDate(data);}} />
                            </div>

                        </div>
                        <div className="flex flex-row justify-end">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-3" onClick={()=>raporAl()}>
                                Rapor Al
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
