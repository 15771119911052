import QRCode from "react-qr-code";
import {toast} from "react-toastify";
import {useState} from "react";
import Dialog from "@mui/material/Dialog";
export default function CihazBenzersizDatasi({datas}) {
    const [zoom, setZoom] = useState(false);
    const copyData = (data) => {
        navigator.clipboard.writeText(data).then(function() {
            toast.success("Kopyalandı");
        }, function(err) {
            toast.error("Kopyalanamadı");
        });

    }
    const printData = () => {
        const canvasDiv = document.getElementById('printArea').innerHTML
        const originalContents = document.body.innerHTML

        const style = `
            <style>
                @page { size: auto;  margin: 0mm; }
                body { margin: 50px; }
                svg {
                    width: 100% !important;
                    height: 100% !important;
                }
            </style>
        `;
        if (document.getElementById != null) {
            var html = '<HTML>\n<HEAD>\n';
            if (document.getElementsByTagName != null) {
                var headTags = document.getElementsByTagName("head");
                if (headTags.length > 0) html += headTags[0].innerHTML;
            }

            html += '\n</HE' + 'AD>\n<BODY>\n';
            html += style;
            var printReadyElem = document.getElementById("printArea");

            if (printReadyElem != null) html += printReadyElem.innerHTML;
            else {
                alert("Error, no contents.");
                return;
            }

            html += '\n</BO' + 'DY>\n</HT' + 'ML>';
            var printWin = window.open("", "processPrint");
            printWin.document.open();
            printWin.document.write(html);
            printWin.document.close();

            printWin.print();

            printWin.close();
        }
    }

    return(
        <td>
            {datas.mesai_gecis_tur_id === 'Karekod' &&
                <div className="flex flex-row p-3">
                   <div   id="printArea" onClick={()=>setZoom(true)}>
                       <QRCode

                           size={56}

                           value={datas.device_unique_info}
                           viewBox={`0 0 56 56`}
                       />
                   </div>

                    <div className="text-xs text-gray-500 flex flex-col p-1">
                        <span>{datas.device_unique_info}</span>

                        <button className="bg-blue-500 hover:bg-blue-400 text-white px-2 py-1 rounded-md mt-2" onClick={()=> printData()}>Yazdır</button>
                    </div>
                </div>
            }
            {datas.mesai_gecis_tur_id !== 'Karekod' && datas.device_unique_info}
            <Dialog open={zoom} onClose={()=>setZoom(false)}>
                <div className="flex flex-col items-center justify-center p-5">
                    <QRCode
                        size={256}
                        value={datas.device_unique_info}
                        viewBox={`0 0 256 256`}
                    />
                    <button className="bg-blue-500 hover:bg-blue-400 text-white px-2 py-1 rounded-md mt-2" onClick={()=> printData()}>Yazdır</button>
                </div>
            </Dialog>
        </td>
    )
}
