import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import App from './App';
import Login from "./views/Login";

const root = ReactDOM.createRoot(document.getElementById('root'));
const token =localStorage.getItem('token')?localStorage.getItem('token'):undefined
const isLogin = window.location.pathname==="/login" || token === undefined;

root.render(
  <>
      {isLogin?<Login/>:<App/>}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
