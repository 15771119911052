import {useState,useEffect} from 'react'
import Dialog from "@mui/material/Dialog";
import {BsFolder2Open} from 'react-icons/bs'
import {BsFileEarmarkPdf} from "react-icons/bs";

export default function File({defaultValue,label,name,onChange}){
    const [value, setValue] = useState()
    const [visible, setVisible] = useState(false)
    const silEvent = (index) => {
        let newValue = JSON.parse(defaultValue)
        newValue.splice(index,1)
        setValue(newValue)
        onChange({
            target: {
                name: name,
                value: JSON.stringify(newValue)
            }
        })
    }
    useEffect(() => {
        if(defaultValue){
            const baseUrl = "https://bulut.kuvarssoft.com/uploads/"
            let newValue = JSON.parse(defaultValue)
             newValue= newValue.map((item)=>{
                return(baseUrl+item.destination_path+item.file_name)
            })
            setValue(newValue)

        }
    }, [defaultValue])
    return(
        <div className="relative">
            <label className="bg-white absolute top-[-10px] text-sm left-[10px] px-1">{label}:</label>
            <div className="flex flex-row">
                <input type="file" id={name} className="border border-gray-300 hover:border-gray-600 rounded p-3 w-full block"/>
                {
                    value && value.length>0 &&
                    <>
                    <button className="bg-yellow-500 hover:bg-yellow-600 text-white px-5 ml-3 py-1 rounded  " onClick={()=>{setVisible(true);}}><BsFolder2Open className="text-black"/></button>
                    <Galeri visible={visible} setVisible={setVisible} images={value} silEvent={silEvent}/>
                    </>
                }

            </div>
        </div>
    )
}
function Galeri({visible,setVisible, images,silEvent}){
    const getFileUzanti = (fileName) => {
        const myArray = fileName.split(".");
        const uzanti = myArray[myArray.length-1];
        return uzanti;
    }
    let [active, setActive] = useState(0)
    return(
        <Dialog open={visible} onClose={()=>setVisible(false)}>
            <div className="flex flex-col">
                <div className="p-5 flex">
                    {getFileUzanti(images[active]) === 'pdf' && <BsFileEarmarkPdf className="w-14 h-14 rounded p-2" />}
                    <a href={images[active]} target="_blank" rel="noreferrer" className="text-sky-500">{images[active]}</a>
                </div>
                <div>
                    {images && <img src={images[active]} alt=""/>}
                </div>

                    <div className="flex flex-row justify-between p-3">
                        <button className="bg-slate-500 hover:bg-slate-600 text-white px-5 py-1 rounded " onClick={()=>active>0 && setActive(active-1)}>Önceki</button>
                        <button className="bg-red-500 hover:bg-red-600 text-white px-5 py-1 rounded " onClick={()=>silEvent(active)}>Sil</button>
                        <button className="bg-slate-500 hover:bg-slate-600 text-white px-5 py-1 rounded " onClick={()=>active<images.length-1 && setActive(active+1)}>Sonraki</button>
                    </div>

            </div>
        </Dialog>

    )
}
