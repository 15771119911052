import  {BsList} from 'react-icons/bs'
import React, {useEffect, useState} from "react";
export default function MobileNav({asideShow,updateAsideShow}) {
    const user_data=localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')):undefined
    const [user,setUser]=useState(user_data.user)
    const [profilePicture,setProfilePicture]=useState({})
    useEffect(() => {
        setUser(user_data.user)
        const photo = JSON.parse(user.profile_picture)[0]
        photo ? setProfilePicture('https://bulut.kuvarssoft.com/uploads/'+ photo.destination_path + photo.file_name):
            setProfilePicture('/profile.png')
    }, [])
    return(
<div className="fixed md:hidden bottom-0  w-full  p-1 z-[2]">
    <div className=" shadow-gray-700 shadow-lg border-gray-300 border h-[70px] w-full bg-white rounded-lg flex flex-row p-2">
        <button className="bg-gray-100 border-gray-600 border rounded-lg px-3">
            <BsList className="text-3xl text-black" onClick={()=>updateAsideShow(!asideShow)}/>
        </button>
        <div className="flex-1 flex flex-row justify-end items-center mr-2">
            <span className="mr-3">{user.name_basic} {user.surname}</span>
            <img src={profilePicture} alt="profile" className=" rounded-full w-12 h-12" />
        </div>
    </div>

</div>

    )
}