import React, {useEffect, useState} from "react";
import {GoogleMap,Polygon, useJsApiLoader} from "@react-google-maps/api";
import Dialog from "@mui/material/Dialog";
import {register} from 'ol/proj/proj4';
import WKT from 'ol/format/WKT.js';
import {toLonLat} from "ol/proj";
import proj4 from 'proj4';
export default function Alan ({datas}) {
    const [visible,setVisible] = useState(false)
    const [center,setCenter] = useState({lat:39.841881,lng: 32.835904})
    const [paths,setPaths] = useState([])
    useEffect(()=>{
        if(datas) {
            proj4.defs('EPSG:7932', '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs');

            register(proj4);
            const format = new WKT();

            const feature = format.readFeature(datas);
            const coordinates = []
            // eslint-disable-next-line array-callback-return
            feature.getGeometry().getCoordinates()[0].map((item) => {
                coordinates.push({
                    lat: parseFloat(toLonLat(item, 'EPSG:7932')[1]),
                    lng: parseFloat(toLonLat(item, 'EPSG:7932')[0])
                })
            })
            setPaths(coordinates)
            console.log(getCenter(coordinates),"center")
            setCenter(getCenter(coordinates))
        }





    },[datas])
    const getCenter = (poly)=> {
        let minX, maxX, minY, maxY;
        poly.forEach((point) => {
            if (minX == null || point.lat < minX) {
                minX = point.lat;
            }
            if (maxX == null || point.lat > maxX) {
                maxX = point.lat;
            }
            if (minY == null || point.lng < minY) {
                minY = point.lng;
            }
            if (maxY == null || point.lng > maxY) {
                maxY = point.lng;
            }
        })
        return {
            lat: (minX + maxX) / 2,
            lng: (minY + maxY) / 2
        }
    }
    const getZoom = (poly) => {
        let minX, maxX, minY, maxY;
        poly.forEach((point) => {
            if (minX == null || point.lat < minX) {
                minX = point.lat;
            }
            if (maxX == null || point.lat > maxX) {
                maxX = point.lat;
            }
            if (minY == null || point.lng < minY) {
                minY = point.lng;
            }
            if (maxY == null || point.lng > maxY) {
                maxY = point.lng;
            }
        })
        const deltaX = maxX - minX;
        // eslint-disable-next-line no-unused-vars
        const deltaY = maxY - minY;
        return Math.min(
            21,
            Math.max(
                1,
                Math.floor(
                    Math.log(360 / deltaX) / Math.LN2
                )
            )
        );
    }
    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    const options = {
        fillColor: "red",
        fillOpacity: 0.7,
        strokeColor: "red",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1
    }
    const libraries = ['drawing','places'];
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAlx419IuJAost_y_fMCVPst4c7YKgpnRU",
        libraries
    })

    // eslint-disable-next-line no-unused-vars
    const [map, setMap] = React.useState(null)



    const onUnmount = React.useCallback(function callback() {
        setMap(null)
    }, [])
    return (
        isLoaded ?
        <>
            <td>
                <button className="px-4 py-2 bg-slate-500 hover:bg-slate-400 text-white rounded" onClick={()=>setVisible(true)}>Alan</button>
            </td>
            <Dialog
                open={visible}
                onClose={()=>setVisible(false)}
                classes={{
                    paper: 'm-0 p-0 w-screen md:w-[600px] lg:w-[800px] xl:w-[1100px]'
                }}
            >
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={getZoom(paths)}

                    onUnmount={onUnmount}
                >
                    { /* Child components, such as markers, info windows, etc. */ }
                    <Polygon
                        paths={paths}
                        options={options}
                    />
                </GoogleMap>


            </Dialog>

        </>:<div></div>
    )
}
