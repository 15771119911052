
import Dialog from '@mui/material/Dialog';
import {AiOutlineCloseCircle} from "react-icons/ai";
import {useEffect,useState} from "react";
import request from "../../request";
import {toast} from "react-toastify";
import Kamera from "../../components/Kamera";
export default function Konum({visible,setVisible}) {
    const [location,setLocation] = useState({latitude: 0,longitude: 0,})
    useEffect(()=>{
        if(visible){
            getLocation()
        }
    },[visible])
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((loc)=>{
                setLocation(loc.coords)
            });
        } else {
            toast.warning("Tarayıcınız konum desteklememektedir.");
        }
    }

    const mesaiBaslat = (photo) => {
        request.konumIleMesaiBaslat(`POINT(${location.longitude} ${location.latitude})`).
        then((res)=>{
            if(res.data.status === 'success'){
                const params={
                    "fotograf_base64_image": [
                        {
                            "name": "ID"+res.data.data.user.id,
                            "image": photo
                        }
                    ],
                    "id": res.data.data.user.id,
                    "record_token": res.data.data.user.record_token
                }
                sendPhotoViaUser(params)
                toast.success("Konum alındı.")
                setVisible(false)
            }else{
                if(res.data.data.message ==="you.are.uncorrect.position"){
                    toast.error("Konumunuz yanlış.")
                }else{

                toast.error(res.data.data.message)
                }
            }

        })
    }
    const sendPhotoViaUser = (params) => {
        request.sendPhotoViaUser(params).then((res)=>{
            if(res.data.status === 'success'){
                toast.success("Mesai başlatıldı.")
                setVisible(false)
            }else{
                toast.error(res.data.data.message)
            }
        })
    }

    return(
        <Dialog onClose={()=>setVisible(false)} open={visible}>
            <div className="">
                <div className="py-2 px-4 flex flex-row justify-between">
                    <h1>Konum ile mesai başlat</h1>
                    <AiOutlineCloseCircle className="text-2xl text-red-600 cursor-pointer" onClick={()=>setVisible(false)}/>
                </div>

                <Kamera setData={mesaiBaslat} />

            </div>
        </Dialog>
    )
}
