export default function GirisCikisDurumu({datas}) {
    return(
        <td>
            <span className={[
                datas === "Gelmedi" ? " bg-red-500 " : "",
                datas.search('fazla') !== -1 ? " bg-green-700 " : "",
                datas.search('az çalıştı')  !== -1 ? " bg-yellow-500 " : "",
                datas.search('İzni var')  !== -1 ? " bg-sky-700  " : "",
                " text-white px-2 py-1  rounded-lg whitespace-nowrap m-1"
            ]}>{datas}</span>
        </td>
    )
}
