import React, {useEffect, useState} from 'react'
import {AiOutlineBarChart,} from 'react-icons/ai'
import {BsCaretDownFill, BsCaretUpFill,} from 'react-icons/bs'
import yonetici from "./List/yonetici";
import mudur from "./List/mudur";
import personel from "./List/personel";
import dis_personel from "./List/dis_personel";
import ik_personel from "./List/ik_personel";
import bekci from "./List/bekci";



export default function List({closeAside}) {
    const [menu,setMenu] = useState([])
    const [path,setPath] = useState(window.location.pathname)
    useEffect(()=>{
        setPath(window.location.pathname)
        if(localStorage.getItem('yetki') === "1"){
            setMenu(mudur.menu)
            setActivePath(mudur.menu)
        }
        else if(localStorage.getItem('yetki') === "2"){
            setMenu(personel.menu)
            setActivePath(personel.menu)
        }
        else if(localStorage.getItem('yetki') === "3"){
            setMenu(dis_personel.menu)
            setActivePath(dis_personel.menu)
        }
        else if(localStorage.getItem('yetki') === "4"){
            setMenu(ik_personel.menu)
            setActivePath(ik_personel.menu)
        }
        else if(localStorage.getItem('yetki') === "5"){
            setMenu(bekci.menu)
            setActivePath(bekci.menu)
        }
        else{
            setMenu(yonetici.menu)
            setActivePath(yonetici.menu)
        }



    },[])
    const setActivePath = (_menu) => {

        _menu.map((item,Parentindex)=>{

            item.sub.map( (subItem) =>{
                if (subItem.link === path) {
                    const newMenu = [..._menu]
                    newMenu[Parentindex].show = true
                    setMenu(newMenu)
                }
            })
        })
    }
    const showMenu = (index) => {
        const newMenu = [...menu]
        newMenu[index].show = !newMenu[index].show
        setMenu(newMenu)
    }
    return(
        <aside  className="my-3 bg-slate-700">
            <div className="h-full px-3 py-4 overflow-y-auto !text-white">
                <a href="/"  className="text-lg font-semibold text-gray-100 mb-1  cursor-pointer">
                    <div className="flex flex-row items-center justify-between px-1">
                        <div className="flex"><AiOutlineBarChart className={` text-2xl`}/> <span className="ml-2">Gösterge</span></div>


                    </div>
                </a>
                <hr  className="my-3 border-slate-5r00"/>
                {menu.map((item,index)=>(
                    <div key={item.title}>
                        <h3 className="text-lg font-semibold text-gray-100 mb-1 cursor-pointer" onClick={()=>showMenu(index)}>
                            <div className="flex flex-row items-center justify-between px-1">
                                <div className="flex">{item.icon} <span className="ml-2">{item.title}</span></div>
                                <BsCaretDownFill className={`${item.show ? 'hidden':'inline'} text-2xl`}/>
                                <BsCaretUpFill className={`${item.show ? 'inline':'hidden'} text-2xl`}/>
                            </div>
                        </h3>
                        <ul key={'list'+index} className={`${item.show ? '':'hidden'} space-y-2`} >
                            {item.sub.map((subItem,subIndex)=>(
                                <li key={'sub'+subIndex}>
                                    {subItem.link &&
                                        <a href={subItem.link} onClick={closeAside}
                                           className={`${path === subItem.link ?' bg-slate-500 ':'bg-slate-700'} flex items-center p-2 text-base font-normal text-gray-900 rounded-lg   hover:bg-slate-500/70 `}>
                                            <div className="flex">{subItem.icon}</div>
                                            <span className="ml-3 text-white">{subItem.title}</span>
                                        </a>
                                    }
                                    {subItem.component &&subItem.component}


                                </li>
                            ))}

                        </ul>
                        <hr key={'hr'+index} className="my-3 border-slate-5r00"/>
                    </div>
                )
                )}
            </div>
        </aside>

    )
}
