import Dialog from "@mui/material/Dialog";
import {useState} from "react";

export default function TurData({datas}) {
const [visible,setVisible] = useState(false);
    return(
        <td className={"p-2"}>
            <button onClick={()=>setVisible(true)} className={"bg-sky-500 text-white px-3 py-2 rounded"}>Sorular</button>
            <Detaylar datas={datas} visible={visible} setVisible={setVisible} />
        </td>
    )
}
function Detaylar({datas,visible,setVisible}) {
    return(
        <Dialog open={visible} onClose={() => setVisible(false)}>
            <div className={"p-2 min-w-[200px]"}>
                <div className={"flex flex-row justify-between items-center mb-2"}>
                    <h3 className={"text-lg font-semibold"}>Sorular</h3>
                    <span className={"text-red-500 cursor-pointer"} onClick={()=>setVisible(false)}>X</span>

                </div>
                {
                    !!datas && JSON.parse(datas).map((data, index) => {
                        return(
                            <div key={index} className={"border p-3 flex flex-col w-[350px] max-w-full mb-2 rounded bg-sky-500 text-white"}>
                                <span className={"whitespace-nowrap"}>Tip: {data.type}</span>
                                <span>Soru: {data.soru}</span>
                                <span>Doğrulama: {data.validations}</span>
                            </div>
                        )
                    })
                }
            </div>
        </Dialog>
    )
}
