import {useEffect, useState} from "react";
import request from "../../request";
import {toast} from "react-toastify";
import Dialog from "@mui/material/Dialog";
import Inputs from "../../components/Inputs";
import Sorular from "./Sorular";

export default function Noktalar({open, setOpen,editId,saveId}){
    const [columns, setColumns] = useState({
        name_basic: undefined
    });
    const [params, setParams] = useState({
        tur_nokta_data:'[{"type":"","soru":"","validations":""}]'
    });
    const [loading, setLoading] = useState(true);


    const getCreateForm = () => {
        setLoading(true)
        request.create('tur_noktalar').then((res) => {
            setColumns(res.data.data.column_set.column_arrays[0].columns);

            setLoading(false)
        })
    }
    const getEditForm = () => {
        setLoading(true)
        request.edit('tur_noktalar',editId).then((res) => {
            setColumns(res.data.data.column_set.column_arrays[0].columns);
            setParams(res.data.data.record)
            setLoading(false)
        })
    }
    const onChange = (e) => {
        let _params = params
        _params[e.target.name] = e.target.value
        setParams(_params);
        console.log(params)
    }

    const saveData = () => {
        const formData = new FormData();
        for (const [key,val] of Object.entries(params)) {
            formData.append(key,val)
        }
        request.add('tur_noktalar',formData).then((res) => {
            if(res.data.data.message === 'success'){
                toast.success('Başarıyla Eklendi')
                setOpen(false)
                saveId(res.data.data.id)
            }else{
                toast.error('Hata Oluştu')
            }
        })
    }

    useEffect(() => {
        if(editId>0) getEditForm()
        else getCreateForm()
    }, [editId]);
    useEffect(() => {
        console.log(params)
    }, [params]);


    return(
        <Dialog open={open} onClose={() => setOpen(false)} classes={{paper:"!max-w-[90vw]"}}>
            <div className={"p-5 w-[700px] max-w-full"}>
                <div className={" flex flex-col"}>
                    <span className="text-xl border-b pb-2 mb-3 w-full">Bekçi Rotası</span>
                    {!loading ?
                        <div className={"flex flex-col"}>

                            <Inputs className={"mb-3"} params={params} changeHandler={onChange} column={columns.name_basic} tableName={'tur_noktalar'} />
                            <Inputs className={"mb-3"} params={params} changeHandler={onChange} column={columns.rfid_kart_id} tableName={'tur_noktalar'} />
                            <Inputs className={"mb-3"} params={params} changeHandler={onChange} column={columns.tur_nokta} tableName={'tur_noktalar'} />
                            <Inputs className={"mb-3"} params={params} changeHandler={onChange} column={columns.description} tableName={'tur_noktalar'} />
                            <Sorular sorular={JSON.parse(params.tur_nokta_data) || []} changeSorular={(v)=> onChange({target:{name:'tur_nokta_data',value:JSON.stringify(v)}})} />
                        </div>
                        :
                        <div className="flex justify-center items-center h-96">
                            <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
                        </div>
                    }
                    <div className={"flex flex-row justify-end"}>
                        <button className={"p-2 px-3 bg-green-500 text-white rounded"} onClick={saveData}>Kaydet</button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
