import {createBrowserRouter} from "react-router-dom";

import Gostergeler from "../views/Gostergeler";
import Tablolar from "../views/Tablolar";
const router = createBrowserRouter([
    {
        path: "/",
        element: <Gostergeler/>,
    },
    {
        path: "/tablolar/:tableName",
        element: <Tablolar/>,
    },
    {
        path: "/*",
        element: <div>Not found</div>,
    }

]);
export default router;
