import React, {useEffect, useState} from 'react'
export default function MultiSelect({datas}) {
    const [visible, setVisible] = useState(false)
    const [data, setData] = useState({})
    useEffect(() => {
        setData(JSON.parse(datas))
        document.addEventListener('click', (e) => {
            if(e.target.className.baseVal !== undefined) {
                setVisible(false)
                return;
            }
            if (e.target.className && e.target.className?.search('.detailButton') >-1) return;
            setVisible(false)
        })
    }, [])

    return(
        <td >
            <div className="flex flex-row items-center">
                {!data && <span className="whitespace-nowrap m-1 p-2  rounded">Veri bulunamadı</span> }
                {!!data && Object.values(data).length >0 && Object.values(data).length >2 &&
                    <>
                        {Object.values(data).slice(0,2).map((item, index) => (
                            <>
                                {item?.display ? <span className="whitespace-nowrap m-1 p-2 bg-slate-100 rounded" key={index}>{item.display}</span> : null}
                            </>
                        ))}

                        <span className="whitespace-nowrap m-1 p-2 bg-slate-100 rounded cursor-pointer detailButton" onClick={()=>setVisible(true)}>...</span>
                        <div className="relative">
                            {visible && <div className="absolute right-0 w-[300px] flex flex-row flex-wrap bg-white p-3 shadow-lg border border-gray-300 shadow-gray-400">
                                {Object.values(data).map((item, index) => (
                                    <>
                                        {item?.display ? <span className="whitespace-nowrap m-1 p-2 bg-slate-100 rounded" key={index}>{item.display}</span> : null}
                                    </>
                                ))}
                            </div>}
                        </div>
                    </>
                }
                {!!data && Object.values(data).length <=2 &&
                    <>
                        {!!data && Object.values(data).map((item, index) => (
                            <>
                                {item?.display ? <span className="whitespace-nowrap m-1 p-2 bg-slate-100 rounded" key={index}>{item.display}</span> : null}
                            </>
                        ))}
                    </>
                }

            </div>
        </td>
    )
}
