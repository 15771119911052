import {useEffect, useState} from "react";

import MesaiGecisDetayi from "../widget/Tables/MesaiGecisDetayi";
import GirisCikisDurumu from "../widget/Tables/GirisCikisDurumu";
import CihazBenzersizDatasi from "../widget/Tables/CihazBenzersizDatasi";
import DateTime from "../widget/Tables/DateTime";
import MultiSelect from "../widget/Tables/MultiSelect";
import Files from "../widget/Tables/Files";
import TurData from "../widget/Tables/TurData";
import Alan from "../widget/Tables/Alan";
import Basarili from "../widget/Tables/Basarili";
import RotaWkt from "../widget/Tables/RotaWkt";
import Boolean from "../widget/Tables/Boolean";
const column_refs= {
    name:"",
    gui_type_name:"",
}
export default function TableColumns({column=column_refs, row}) {
    const column_settings ={
        td: {
            class:"bg-slate-700 text-white",
        }
    }
    return(

        <>
            {(()=>{
                if(column.name === 'mesai_gecis_detayi') return <MesaiGecisDetayi datas={row}/>
                else if(column.name === 'device_unique_info') return <CihazBenzersizDatasi datas={row}/>
                else if(column.name === 'alan') return <Alan datas={row[column.name]}/>
                else if(column.name === 'giris_durumu' || column.name === 'cikis_durumu') return <GirisCikisDurumu datas={row[column.name]}/>
                else if(column.name === 'basarili') return <Basarili datas={row[column.name]}/>
                else if(column.name === 'rota_wkt') return <RotaWkt datas={row}/>
                else if(['tur_rota_data','tur_nokta_data'].includes(column.name)) return <TurData datas={row[column.name]}/>
                else if(column.gui_type_name === 'datetime') return <DateTime datas={row[column.name]}/>
                else if(column.gui_type_name === 'files') return <Files datas={row[column.name]}/>
                else if(column.gui_type_name === 'multiselect' || column.gui_type_name === 'multiselectdragdrop') return <MultiSelect datas={row[column.name]}/>
                else if(['boolean','boolean:fastchange'].includes(column.gui_type_name)) return <Boolean datas={row[column.name]}/>
                /*else if(column.gui_type_name === 'text') return <Text datas={row[column.name]}/>*/

                else{
                    return(
                        <td  className={column_settings[column.name]?.td ?column_settings[column.name]?.td :"px-6 py-4 font-medium text-gray-900 "}>
                                <span className={[column_settings[column.name]?.class , " text-gray-600 ellipsis-2"]}>
                                     {row[column.name]}
                                </span>

                        </td>
                    )
                }
            })()}
        </>
    )
}
