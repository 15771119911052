import React, {useEffect,useState} from 'react'
import Dialog from '@mui/material/Dialog';
import { GoogleMap, useJsApiLoader,Polygon,Marker } from '@react-google-maps/api';

export default function MesaiGecisDetayi({datas}) {
    const [data,setData] = useState(JSON.parse(datas.mesai_gecis_detayi))
    const [ipListVisible,setIpListVisible] = useState(false)
    const [mapVisible,setMapVisible] = useState(false)
    const [alanVisible,setAlanVisible] = useState(false)
    const [imgVisible,setImgVisible] = useState(false)
    useEffect(()=>{
        setData(JSON.parse(datas.mesai_gecis_detayi))
    },[datas])
    const getImage = () => {
        if(data?.fotograf?.length>0){
            let foto = data.fotograf[0]
            return 'https://bulut.kuvarssoft.com/uploads/'+ foto.destination_path + foto.file_name

        }
    }

    return(
        <div className="flex flex-col p-6">
            <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                    {!!data?.ips &&
                        <div onClick={()=> setIpListVisible(true)} className=" cursor-pointer text-white font-semibold bg-slate-700 hover:bg-slate-600 whitespace-nowrap p-2 rounded m-1">İp Listesi</div>
                    }
                    {!!data?.konum &&
                    <span onClick={()=> setMapVisible(true)}  className=" cursor-pointer text-white font-semibold bg-slate-700 hover:bg-slate-600 whitespace-nowrap p-2 rounded m-1 text-center">Konum</span>
                    }
                    {!!data?.alanlar &&
                        <span onClick={()=> setAlanVisible(true)}  className=" cursor-pointer text-white font-semibold bg-slate-700 hover:bg-slate-600 whitespace-nowrap p-2 rounded m-1 text-center">Alan</span>
                    }
                    {!!data?.rfid_cihaz_id &&
                        <span className=" text-black font-semibold bg-slate-100 border border-slate-600 whitespace-nowrap p-2 rounded m-1 text-center">{datas.mesai_gecis_tur_id} ID: {data?.rfid_cihaz_id}</span>
                    }
                </div>
                {!!data?.fotograf?.length>0 &&
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <img src={getImage()} onClick={()=> setImgVisible(true)} className="h-32 rounded cursor-pointer m-1 object-cover"  alt="image"/>
                }

            </div>
            <IpList visible={ipListVisible} handleClose={()=>{setIpListVisible(false)}} list={data?.ips} />
            <Harita visible={mapVisible} handleClose={()=>{setMapVisible(false)}} data={data?.konum}/>
            <Alan visible={alanVisible} handleClose={()=>{setAlanVisible(false)}} data={data}/>
            <ImageView visible={imgVisible} handleClose={()=>{setImgVisible(false)}} data={getImage()}/>
        </div>
    )

}
function ImageView ({visible,handleClose,data=[]}) {
    return(
        <Dialog
            open={visible}
            onClose={handleClose}

        >
            <div className="w-[500px] max-w-full">

                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img src={data} className="w-full rounded object-cover"  alt="image"/>

            </div>


        </Dialog>
    )
}
function IpList ({visible,handleClose,list=[]}) {
    return(
        <Dialog
            open={visible}
            onClose={handleClose}

        >
            <div className=" w-[400px] max-w-full">

                <div className="flex flex-col">
                    <div className="flex flex-row justify-between my-1 py-2 px-4">
                        <h2>IP Listesi</h2>
                        <button onClick={handleClose} className="text-red-500">X</button>
                    </div>
                    <hr/>
                    <div className="flex flex-col p-3">
                        {list.map((item)=>(
                            <div className="m-1 bg-slate-200 rounded-xl p-2 ">
                                {item}
                            </div>
                        ))}
                    </div>
                </div>

            </div>


        </Dialog>
    )
}

function Harita ({visible,handleClose,data=""}) {
    const [center,setCenter] = useState({lat:0,lng:0})
    useEffect(()=>{
        let _center = data && data.split('(')[1].split(')')[0].split(' ')
        // eslint-disable-next-line array-callback-return
        _center && _center.map((item,index)=>{
            _center[index] = parseFloat(item)
        })
        _center && setCenter({lat:_center[1],lng:_center[0]})


    },[data])
    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    const libraries = ['drawing','places'];
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAlx419IuJAost_y_fMCVPst4c7YKgpnRU",
        libraries
    })
    // eslint-disable-next-line no-unused-vars
    const [map, setMap] = React.useState(null)



    const onUnmount = React.useCallback(function callback() {
        setMap(null)
    }, [])
    return (
        isLoaded ? <Dialog
            open={visible}
            onClose={handleClose}
            classes={{
                paper: 'm-0 p-0 w-screen md:w-[600px] lg:w-[800px] xl:w-[1100px]'
            }}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}

                onUnmount={onUnmount}
            >
                { /* Child components, such as markers, info windows, etc. */ }
                <Marker
                    position={center}
                />
            </GoogleMap>


        </Dialog>:<></>
    )
}
function Alan ({visible,handleClose,data=""}) {
    const [center,setCenter] = useState({lat:0,lng:0})
    const [paths,setPaths] = useState([])
    useEffect(()=>{
        if(data?.konum){
            let _center = data.konum && data.konum.split('(')[1].split(')')[0].split(' ')
            // eslint-disable-next-line array-callback-return
            _center && _center.map((item,index)=>{
                _center[index] = parseFloat(item)
            })
            _center && setCenter({lat:_center[1],lng:_center[0]})

           // let _paths = data.alanlar?.[0]?.alan.split('((')[1].split(')')[0].split(',')
            // eslint-disable-next-line array-callback-return
            let _paths = []
            
            data.alanlar.map((item,index)=>{
              let _path = item?.alan.split('((')[1].split(')')[0].split(',')
              _path && _path.map((item,index)=>{
                _path[index] = {
                    lat:parseFloat(item.split(' ')[1]),
                    lng:parseFloat(item.split(' ')[0])
                }
            })
            _paths.push(_path)
            })
          
             _paths && setPaths(_paths)
        }
    },[data])
    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    const options = {
        fillColor: "lightblue",
        fillOpacity: 0.4,
        strokeColor: "red",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1
    }
    const libraries = ['drawing','places'];
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAlx419IuJAost_y_fMCVPst4c7YKgpnRU",
        libraries
    })
    //eslint-disable-next-line no-unused-vars
    const [map, setMap] = React.useState(null)



    const onUnmount = React.useCallback(function callback() {
        setMap(null)
    }, [])
    return (
        isLoaded ? <Dialog
            open={visible}
            onClose={handleClose}
            classes={{
                paper: 'm-0 p-0 w-screen md:w-[600px] lg:w-[800px] xl:w-[1100px]'
            }}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={6}

                onUnmount={onUnmount}
            >
                 {paths.map((item,index)=>{
                    return  <Polygon
                    paths={item}
                    options={options}
                />
                 })
                }
                 <Marker
                    position={center}
                />
                { /* Child components, such as markers, info windows, etc. */ }
               
            </GoogleMap>
            


        </Dialog>:<></>
    )
}
