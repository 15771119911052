import {useParams} from "react-router-dom";
import {useEffect, useState} from 'react'
import request from "../request";
import {AiOutlineFilter} from "react-icons/ai";
import {IoAddOutline} from "react-icons/io5";
import Tables from "../components/Tables";
import Dual from "../components/Dual";
import {MdKeyboardArrowUp} from "react-icons/md";


export default function Tablolar() {
    const [data, setData] = useState([])
    const [params, setParams] = useState({
        page: 1,
        limit: 10,
        sorts: {},
        filters: {},
    })

    const [loading, setLoading] = useState(false)
    const [addVisible, setAddVisible] = useState(false)
    const [pageList, setPageList] = useState([])
    const [filtrele, setFiltrele] = useState(false)
    const [paginationMenu, setPaginationMenu] = useState(null)
    const {tableName} = useParams();
    const auths = JSON.parse(localStorage.getItem('userData')).auths.tables[tableName]
    const getData = () => {
        setLoading(true)
        console.log("params",params)
        request.list(tableName, params).then((response) => {
            setData(response.data.data)
            setLoading(false)

            let _pageList = []
            for (let i = 1; i < response.data.data.pages; i++) {
                _pageList.push(i)
            }
            setPageList(_pageList)

        })
    }
    useEffect(() => {
        getData();
    }, [params])

    useEffect(() => {
        !addVisible && getData();
    }, [addVisible])
    const refreshData = () => {
        getData()
    }


    return(
        <div className="flex flex-col p-1 md:p-6 ">

            <div className="shadow-xl p-2 md:p-5 bg-slate-50 rounded-xl">



                <div className="flex flex-col md:flex-row justify-between py-1 md:py-4 items-center">
                    <div className="flex flex-row justify-between items-center w-full md:w-fit">
                        <h1 className="text-2xl font-bold">{data.table_info?.display_name}</h1>
                        {tableName === 'tur_gorevleri' &&
                            <button className="ml-3 bg-slate-400 hover:bg-slate-500 border border-slate-600 text-white px-4 py-2 rounded-lg">Bugünün verileri</button>
                        }
                    </div>
                    <div className="flex flex-row my-2 md:my-0">
                        {auths.queries &&
                            <button className=" bg-slate-400 hover:bg-slate-500 border border-slate-500 text-white px-4 py-2 rounded-lg flex flex-row items-center" onClick={()=>setFiltrele(!filtrele)}>
                                <span>Filtrele</span>
                                <AiOutlineFilter className="text-xl" />
                            </button>
                        }
                        {auths.creates &&
                            <button className="bg-slate-700 hover:bg-slate-600 text-white px-4 py-2 rounded-lg ml-2 flex flex-row items-center" onClick={()=>setAddVisible(true)}>
                                <span>Yeni Kayıt</span>
                                <IoAddOutline className="text-xl" />
                            </button>
                        }



                    </div>
                </div>




                {loading &&
                    <div className="flex flex-row justify-center items-center h-[80vh]">
                        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-slate-700"></div>
                    </div>
                }

                {!loading && <Tables datas={data} refreshData={refreshData} params={params} setParams={setParams} filtrele={filtrele}/>}

                <Dual tableName={tableName} visible={addVisible} setVisible={setAddVisible} type={'create'}/>

                <div className="flex flex-row mt-6 justify-center items-center">

                    <nav >
                        <ul className="inline-flex -space-x-px">
                            <li onClick={()=>{if(params.page>1){params.page--;setParams(params);getData()}}} >
                                <span  className={` ${params.page<2 ?'cursor-no-drop bg-gray-100 text-gray-400':' hover:bg-gray-100 hover:text-gray-700 cursor-pointer text-gray-500 bg-white'} px-2 py-2 ml-0 leading-tight  border border-gray-300 rounded-l-lg `}>
                                    Önceki
                                </span>
                            </li>
                            {
                                pageList.slice(
                                    params.page>3 ? params.page-3 : 0,
                                    params.page+2<data.pages ? params.page+2 : data.pages
                                ).map((page, index) => {
                                    return (
                                        <li key={index} onClick={()=>{params.page = page;setParams(params);getData()}} >
                                            <span  className={`${page === params.page ?'!bg-slate-300':''} cursor-pointer px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}>
                                                {page}
                                            </span>
                                        </li>
                                    )
                                })
                            }

                            <li onClick={()=>{if(params.page<data.pages){params.page++;setParams(params);getData()}}} >
                                <span  className={` ${params.page>=data.pages ?'cursor-no-drop  bg-gray-100 text-gray-400':' hover:bg-gray-100 hover:text-gray-700 cursor-pointer text-gray-500 bg-white'} px-2 py-2 ml-0 leading-tight  border border-gray-300 rounded-r-lg `}>
                                    Sonraki
                                </span>
                            </li>
                        </ul>
                    </nav>

                    <div className={"relative ml-3"}>
                        <button className={"border bg-white px-4 py-1 rounded flex flex-row items-center"} onClick={()=>setPaginationMenu(!paginationMenu)}>
                            {
                                paginationMenu?
                                    <span>X</span>
                                    :
                                    <MdKeyboardArrowUp className={"text-xl"} />
                            }

                            <span className={"ml-2"}>({params.page})</span>
                        </button>
                        {
                            paginationMenu &&
                            <div className="border bg-white rounded absolute bottom-[34px] w-full">
                                <div className="flex flex-col max-h-[150px] pr-[1px] overflow-auto">

                                    <>
                                        { pageList.map((page, index) => {
                                            return (
                                                <button key={index} onClick={()=>{params.page = page;setParams(params);getData()}} className={` ${page === params.page ?'!bg-slate-300':''} cursor-pointer px-1 py-0 leading-tight text-gray-700 bg-white border border-gray-100 rounded mb-1 hover:bg-gray-100 hover:text-gray-700`}>
                                                    {page}
                                                </button>
                                            )
                                        })}
                                    </>

                                </div>
                            </div>
                        }


                    </div>
                </div>
            </div>
        </div>

    )
}
