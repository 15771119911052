import Dialog from "@mui/material/Dialog";
import {AiOutlineCloseCircle} from "react-icons/ai";
import request from "../../request";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";


export default function Tag({visible,setVisible}) {
    const [tagVisible, setTagVisible] = useState(false)

    if ('NDEFReader' in window && tagVisible) {

        // eslint-disable-next-line no-undef
        const ndef = new NDEFReader();
        ndef.scan().then((res) => {
            toast(JSON.stringify(res))

            ndef.onreadingerror = () => {
                toast.error("NFC okunamadı");
            };
            ndef.onreading = (event,msg) => {
                toast(JSON.stringify(event))
            };
            ndef.addEventListener("reading", ({ message, serialNumber }) => {
                toast(JSON.stringify(serialNumber));
                request.tagIleMesaiBaslat(parseInt(serialNumber)).then((res) => {
                    if(res.data.status === "success"){
                        toast.success("Mesai başlatma başarılı")
                    }else{
                        toast.error(res.data.data.message)
                    }
                }).catch((err) => {
                    console.error(err.response);
                    toast.error("Mesai başlatma başarısız")
                })

            });
        }).catch(error => {
            toast.error(`Okuma başarısız: ${error}.`);
        });
    }
    return(
        <div>
            <Dialog onClose={()=>setVisible(false)} open={visible}>
                <div className="w-[420px] flex flex-col p-3 max-w-full">
                    <div className="p-1 flex flex-row justify-between">
                        <h1>NFC Tag ile mesai başlat</h1>
                        <AiOutlineCloseCircle className="text-2xl text-red-600 cursor-pointer" onClick={()=>setVisible(false)}/>
                    </div>
                    <hr className="my-1"/>
                    <div className="mt-4">
                        NFC Tag ile mesai başlatmak için onaylayınız.
                        {'NDEFReader' in window ?
                            <div className="flex flex-row mt-5 justify-end">
                                {tagVisible && <div className="text-green-500">Tarama başlatıldı.</div>}
                                <button className="bg-green-500 text-white px-2 py-1 rounded-md mr-2" onClick={()=>setTagVisible(true)}>Onayla</button>
                                <button className="bg-red-500 text-white px-2 py-1 rounded-md">İptal</button>
                            </div>
                        :

                            (()=>{
                                if(visible){
                                    toast.error("Tarayıcı NFC desteği bulunmuyor.")
                                    setVisible(false)
                                }

                            })()
                        }


                    </div>
                </div>
            </Dialog>
        </div>

    )
}
