import React, {useEffect, useState} from 'react'
import request from "../../request";
import {BsBuildings} from "react-icons/bs";
export default function AltBirimCount() {
    const [series, setSeries] = useState([])
    const getSeries = () => {
        request.getDashboardData("RecordCount:departments:0").then((res) => {
            setSeries(res.data.data)

        })
    }
    useEffect(() => {
        getSeries()
    }, [])
    return (
        <div className="flex flex-row items-center bg-slate-500 p-3 rounded-full text-xl">
            <BsBuildings className=" text-gray-200"/>
            <span className=" ml-3 text-white">Alt birimler sayısı</span>
            <span className=" ml-5 bg-slate-200 px-2 rounded-full text-black">{series.count}</span>
        </div>
    )

}
