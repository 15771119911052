import {DrawingManager, GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import React, {useEffect, useRef, useState} from 'react';
import proj4 from "proj4";
import {register} from "ol/proj/proj4";
import * as olProj from "ol/proj";
import WKT from "ol/format/WKT";
import {toLonLat} from "ol/proj";
const MapComponent = ({defaultValue, name,onChange}) => {
    const drawingManagerRef = useRef();
    const markerRef = useRef();
    const [location, setLocation] = useState({});
    const libraries = ['drawing','places'];
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAlx419IuJAost_y_fMCVPst4c7YKgpnRU',
        libraries
    });

    useEffect(() => {
        if (defaultValue) {

            proj4.defs('EPSG:7932', '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs');
            register(proj4);

            const format = new WKT();

            const  feature = format.readFeature(defaultValue);
            let coordinate = toLonLat(feature.getGeometry().getCoordinates(), 'EPSG:7932')
            setLocation({lat: coordinate[1], lng: coordinate[0]})
            setCenter({lat: coordinate[1], lng: coordinate[0]})
            console.log({lat: coordinate[1], lng: coordinate[0]})


        }
    })

    let drawingManagerOptions = {
        drawingControl: true,
        drawingControlOptions: {
            position: window.google?.maps.ControlPosition.TOP_LEFT,
            drawingModes: [
                window.google?.maps.drawing.OverlayType.MARKER,

            ],
        },

    }

    const mapContainerStyle = {
        height: "400px",
        width: "100%",
        borderRadius: "10px",
    }

    const [center,setCenter] = useState({lat: 41.0082, lng: 28.9784});

    const onLoad = drawingManager => {
        drawingManagerRef.current = drawingManager;
        console.log(drawingManagerRef)
    }

    const onLoadMarker = marker => {
        markerRef.current = marker;
    }

    const onMarkerComplete = marker => {
        const position = {
            lat: marker.getPosition().lat(),
            lng: marker.getPosition().lng()
        };

        drawingManagerRef.current.setDrawingMode(null);
        setLocation(position)
        setCenter(position)

        proj4.defs('EPSG:7932', '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs');
        register(proj4);

        let _point = olProj.transform([position.lng,position.lat], 'EPSG:4326', 'EPSG:7932')
        console.log(_point)
        onChange({
            target: {
                name: name,
                value: "POINT("+_point[0]+' '+_point[1] +")",
                deger:'polygons use effect'
            }
        })
        setTimeout(function() {
            console.log(markerRef.current.getPosition().lat())
            marker.setMap(null);
            setCenter(position)
        }, 500);

    }

    return(
        <div className='map-container' style={{ position: 'relative' }}>

            {isLoaded ? (
                <GoogleMap
                    id="drawing-manager-example"
                    mapContainerStyle={mapContainerStyle}
                    zoom={7}
                    center={center}
                    mapContainerClassName={"w-full h-full"}
                >
                    <DrawingManager
                        onLoad={onLoad}
                        onMarkerComplete={onMarkerComplete}
                        options={drawingManagerOptions}
                    />

                        <Marker position={location} onLoad={onLoadMarker} />

                </GoogleMap>
            ) : null}
        </div>
    )
}

export default MapComponent;
