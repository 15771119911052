export default function Basarili({datas}) {
    return (
        <>
            {datas ? (
                <td scope="row" className="px-6 py-4 font-medium text-gray-900 ">
                    <span className="text-white bg-green-500 p-2 rounded">
                        Başarılı
                    </span>
                </td>
            ) : (
                <td scope="row" className="px-6 py-4 font-medium text-gray-900 ">
                    <span className="text-white bg-red-500 p-2 rounded">
                        Başarısız
                    </span>
                </td>
            )}
        </>
    )
}
