import {TextField} from "@mui/material";
import Select from "../widget/inputs/Select";
import MultiSelect from "../widget/inputs/MultiSelect";
import File from "../widget/inputs/File";
import Boolean from "../widget/inputs/Boolean";
import Polygon from "../widget/inputs/Polygon";
import Point from "../widget/inputs/Point";
import Sorular from "../widget/Bekci/Sorular";
import {MdOutlineAddLocationAlt} from "react-icons/md";
import { useState} from "react";
import Dual from "./Dual";

export default function Inputs({className,column,tableName, params,changeHandler,type,keyPressHandler}){
    const [noktaVisible, setNoktaVisible] = useState(false);
    const [noktaLoading, setNoktaLoading] = useState(false);
    return(
        <>
            <div className={className}>
                {
                    ['tur_nokta_data',"tur_rota_data"].includes(column.name) &&
                    <Sorular label={column.display_name} name={column.name} tableName={tableName} defaultValue={params[column.name]} onChange={changeHandler} />
                }
                {
                    column.name ==='tur_nokta_ids' &&
                    <>
                        <div className="flex flex-row w-full">
                            {!noktaLoading && <MultiSelect className={"w-full"} label={column.display_name} name={column.name} tableName={tableName} defaultValue={params[column.name]} onChange={changeHandler}/>}
                            <button className={"h-14 w-14 ml-2 rounded bg-sky-700 hover:bg-sky-600 text-3xl text-white flex flex-row items-center justify-center"}
                                    onClick={()=>  setNoktaVisible(true)}>
                                <MdOutlineAddLocationAlt />
                            </button>
                        </div>
                        <Dual visible={noktaVisible} setVisible={(e)=>{setNoktaVisible(e);setNoktaLoading(true);setTimeout(()=>{setNoktaLoading(false)},200)}} tableName={"tur_noktalar"} type={"create"}  />
                    </>
                }
                {
                    column.gui_type_name === "string" &&
                    <TextField label={column.display_name}  name={column.name}   value={params[column.name]} onChange={changeHandler} onKeyPress={keyPressHandler} className={`w-full block`}/>
                }
                {
                    ['select','select:static'].includes(column.gui_type_name) &&
                    <Select label={column.display_name} name={column.name} tableName={tableName} defaultValue={params[column.name]} onChange={changeHandler} onKeyPress={keyPressHandler} type={type}/>
                }
                {
                    ['multiselect:static','multiselect','multiselectdragdrop' ].includes(column.gui_type_name) &&
                    !['tur_nokta_ids'].includes(column.name) &&
                    <MultiSelect label={column.display_name} name={column.name} tableName={tableName} defaultValue={params[column.name]} onChange={changeHandler} onKeyPress={keyPressHandler} type={type}/>
                }
                {
                    ['richtext','text'].includes(column.gui_type_name) &&
                    <TextField  label={column.display_name}   name={column.name} value={params[column.name]} onChange={changeHandler} onKeyPress={keyPressHandler} className="w-full block" multiline rows={ type ==="filters"?1: 3}/>
                }
                {
                    column.gui_type_name === "files" && type !=="filters" &&
                    <File defaultValue={params[column.name]} label={column.display_name}   name={column.name} onChange={changeHandler}  />
                }
                {
                    column.gui_type_name === "password" && type !=="filters" &&
                    <TextField  label={column.display_name}   name={column.name} value={params[column.name]} onChange={changeHandler} onKeyPress={keyPressHandler} className="w-full block" type="password"/>
                }
                {
                    column.gui_type_name === "boolean:fastchange" &&
                    <Boolean label={column.display_name} name={column.name} defaultValue={params[column.name]} onChange={changeHandler} onKeyPress={keyPressHandler} />
                }
                {
                    column.gui_type_name === "date" &&
                    <div className="relative">
                        <label className="bg-white absolute top-[-10px] text-sm left-[10px] px-1">{column.display_name}</label>
                        <input type="date" className="border border-gray-300 hover:border-gray-600 rounded p-3 w-full block" onKeyPress={keyPressHandler}
                               name={column.name}  value={params[column.name]} onChange={changeHandler} />
                    </div>
                }
                {
                    column.gui_type_name === "datetime" &&
                    <div className="relative">
                        <label className="bg-white absolute top-[-10px] text-sm left-[10px] px-1">{column.display_name}</label>
                        <input type="datetime-local" className="border border-gray-300 hover:border-gray-600 rounded p-3 w-full block" onKeyPress={keyPressHandler}
                               name={column.name} value={params[column.name]} onChange={changeHandler} />
                    </div>

                }
                {
                    column.gui_type_name === "time" &&
                    <div className="relative">
                        <label className="bg-white absolute top-[-10px] text-sm left-[10px] px-1">{column.display_name}</label>
                        <input type="time" className="border border-gray-300 hover:border-gray-600 rounded p-3 w-full block" onKeyPress={keyPressHandler}
                               name={column.name} value={params[column.name]} onChange={changeHandler} />
                    </div>
                }
                {
                    column.gui_type_name === "numeric" &&
                    <TextField  label={column.display_name}   name={column.name} value={params[column.name]} onChange={changeHandler} onKeyPress={keyPressHandler} className="w-full block" type="number"/>
                }
                {
                    column.gui_type_name === "polygon" &&
                    <div>
                        <label className="">{column.display_name}</label>
                        <Polygon label={column.display_name} name={column.name} defaultValue={params[column.name]} onChange={changeHandler}  />
                    </div>
                }
                {
                    ['point'].includes(column.gui_type_name) &&
                    <div>
                        <label className="">{column.display_name}</label>
                        <Point name={column.name} defaultValue={params[column.name]} onChange={changeHandler}  />
                    </div>

                }



            </div>

        </>
    )
}
