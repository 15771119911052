
import {BiWalk} from "react-icons/bi";
import {SiAdguard} from "react-icons/si";

export default {
    menu:[

        {
            title:"Bekçi Tur İşlemleri",
            icon:<BiWalk  className="text-2xl text-white"/>,
            show:false,
            sub:[
                {
                    title:"Bekçi Turları",
                    icon:<SiAdguard className="text-2xl text-white"/>,
                    link:"/tablolar/tur_gorevleri"
                },


            ]

        }
    ]
}
