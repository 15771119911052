import Dialog from '@mui/material/Dialog';
import {useEffect,useState} from "react";
import request from "../request";
import {IoCloseOutline} from "react-icons/io5";
import {toast} from "react-toastify";
import moment from "moment";
import Inputs from "./Inputs";
import Bekci from "../components/Bekci";

export default function Dual({type,tableName,id,visible,setVisible}) {
    const [loading, setLoading] = useState(true)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [columns, setColumns] = useState({})
    const [tableData, setTableData] = useState({})
    const [params, setParams] = useState({})
    const [errors, setErrors] = useState({})
    useEffect(() => {
        setParams({})
        setErrors({})
        if(visible && type==='create') getCreate()
        if(visible && type==='edit') getEdit()
    }, [tableName,id,visible])

    const getCreate = () => {
        request.create(tableName).then((res) => {
            setColumns(res.data.data.column_set.column_arrays[0].columns)
            setTableData(res.data.data.table_info)
        }).catch((err) => {
            console.error(err)
        }).finally(() => {
            setLoading(false)
        })
    }
    const getEdit = () => {
        request.edit(tableName,id).then((res) => {
            setColumns(res.data.data.column_set.column_arrays[0].columns)
            setTableData(res.data.data.table_info)
            setParams(res.data.data.record)
        }).catch((err) => {
            console.error(err)
        }).finally(() => {
            setLoading(false)
        })
    }
    const saveData = () => {
        setButtonLoading(true)
        const formData = new FormData();
        for (const [key, value] of Object.entries(columns)) {
            if(params[key] === null || params[key] ===undefined) continue;
            else if(value.gui_type_name.includes('boolean')) formData.append(key, params[key]?1:0);
            else if(value.gui_type_name == 'date' && params[key] !== "") formData.append(key, moment(params[key]).format('YYYY-MM-DD'));
            else if(value.gui_type_name == 'datetime' && params[key] !== "") formData.append(key, moment(params[key]).format('YYYY-MM-DD HH:mm:ss'));
            else if(value.gui_type_name == 'time' && params[key] !== "") formData.append(key, moment(moment().format('YYYY-MM-DD')+" "+ params[key]).format('HH:mm:ss'));
            else if(value.gui_type_name.includes('file')) {
                const file = document.getElementById(key).files[0];
                if(file) formData.append(key+'[]', file);
                formData.append(key+'_old', params[key])
            }
            else formData.append(key,params[key]);
        }
        if(type==='create')
            request.add(tableName,formData).then((res) => {
                if(res.data.data.message ==='error'){
                    for (const [clm_name,error] of Object.entries(res.data.data.errors)) {
                        toast.error(columns[clm_name].display_name+ ':  '+ error)
                    }
                    setErrors(res.data.data.errors)
                }
                if(res.data.data.message ==='success'){
                    toast.success("Kayıt başarıyla güncellendi")
                    setVisible(false)
                }
                if(res.data.status ==='error'){
                    toast.error(res.data.data.message)
                }
            }).catch(() => {
                toast.error("Bir hata oluştu")
            }).finally(() => {
                setButtonLoading(false)
            })
        if(type ==='edit'){
            request.update(tableName,id,formData).then((res) => {
                if(res.data.data.message ==='error'){
                    for (const [clm_name,error] of Object.entries(res.data.data.errors)) {
                        toast.error(columns[clm_name].display_name+ ':  '+ error)
                    }
                    setErrors(res.data.data.errors)
                }
                if(res.data.data.message ==='success'){
                    toast.success("Kayıt başarıyla güncellendi")
                    setVisible(false)
                }
                if(res.data.status ==='error'){
                    toast.error(res.data.data.message)
                }
            }).catch((err) => {
                toast.error(err.response.data.message)
            }).finally(() => {
                setButtonLoading(false)
            })
        }




    }
    const changeHandler = (e) => {
        setParams({...params,[e.target.name]:e.target.value})
    }

    return (
        <>
            {tableName==='tur_rotalar!' && <Dialog open={visible} onClose={()=>setVisible(false)} classes={{
                paper: "w-[94vw] xs:w-[70vw] sm:w-[65vw] md:w-[1250px]"
            }}>
                <Bekci editId={id} setVisible={setVisible}/>
            </Dialog>
            }

            {!['tur_rotalar!'].includes(tableName) &&
            <Dialog onClose={()=>setVisible(false)} open={visible} classes={{
                paper: "w-[94vw] xs:w-[70vw] sm:w-[65vw] md:w-[1250px]"
            }}>
                <div className="p-3">
                    <div className=" border-b border-slate-400 pb-2 mb-2 flex  flex-row items-center justify-between">
                        <h1 className="text-2xl">
                            {tableData.display_name}
                        </h1>
                        <IoCloseOutline className="text-red-600 text-3xl cursor-pointer" onClick={()=>setVisible(false)} />
                    </div>
                    {loading &&
                        <div className="flex flex-row justify-center items-center h-[300px]">
                            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-slate-700"></div>
                            Yükleniyor...
                        </div>
                    }

                    {!!columns && !loading && Object.values(columns).map((column) => {
                        return(
                            <div className="p-2">
                                <Inputs params={params} tableName={tableName} column={column} changeHandler={changeHandler} />
                                {
                                    errors[column.name] &&
                                    <div className="text-red-600 text-sm">
                                        {errors[column.name]}
                                    </div>
                                }
                            </div>
                        )
                    })}
                    <div className="flex flex-row justify-end p-2">
                        {!buttonLoading &&
                            <button className="bg-green-600 hover:bg-green-500 text-white px-4 py-2 rounded" onClick={saveData}>
                                Kaydet
                            </button>
                        }
                        {buttonLoading &&
                            <button className="bg-gray-600 text-white px-4 py-2 rounded flex-row flex ">
                                Kaydediliyor...
                                <div className="animate-spin rounded-full h-5 w-5 ml-2 border-b-2 border-white"></div>
                            </button>
                        }
                    </div>
                </div>
            </Dialog>
            }
        </>
    )
}
